import { TableSortLabel, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import classnames from 'classnames';
import {
  darken,
  lighten,
  fade,
} from '@material-ui/core/styles/colorManipulator';

const styles = theme => {
  const dividerColor =
    theme.palette.type === 'light'
      ? lighten(fade(theme.palette.divider, 1), 0.88)
      : darken(fade(theme.palette.divider, 1), 0.68);
  return {
    head: {
      backgroundColor:
        theme.palette.type === 'light'
          ? darken(theme.palette.background.paper, 0.1)
          : lighten(theme.palette.background.paper, 0.05),
      borderBottom: `1px solid ${theme.palette.divider}`,
      //   theme.palette.type === 'light'
      //     ? lighten(fade(theme.palette.divider, 1), 0.88)
      //     : darken(fade(theme.palette.divider, 1), 0.68)
      // }`,
      flex: 1,
    },
    column: {
      display: 'flex',
      alignItems: 'center',
      boxSizing: 'border-box',
      height: 48,
    },
  };
};

const ArchTableHeaderCell = ({
  classes,
  children,
  style,
  dataKey,
  sortBy,
  sortDirection,
  disableSort,
  columnData,
}) => {
  return (
    <TableCell
      component="div"
      variant="head"
      style={style}
      align={columnData && columnData.rightAlign ? 'right' : 'left'}
      className={classnames(classes.head, classes.column)}
    >
      {disableSort ? (
        children
      ) : (
        <TableSortLabel
          active={sortBy === dataKey}
          direction={sortDirection && sortDirection.toLowerCase()}
        >
          {children}
        </TableSortLabel>
      )}
    </TableCell>
  );
};

ArchTableHeaderCell.propTypes = {
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  dataKey: PropTypes.string,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  disableSort: PropTypes.bool,
};

ArchTableHeaderCell.defaultProps = {
  style: null,
  dataKey: '',
  sortBy: '',
  sortDirection: '',
  disableSort: false,
};

export default withStyles(styles)(ArchTableHeaderCell);
