import { connect } from 'redux-bundler-react';
import { Button, TextField, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';

import ManeAuth from '../../../models/mane_auth';

import ArchPaper from '../../molecules/ArchPaper';

const styles = theme => ({
  root: {
    display: 'flex',
    height: '100vh',
    background: '#fafafa',
  },
});

function AuthCallback({
  classes,
  githubState,
  authCodeParam,
  githubStateParam,
  doUpdateUrl,
  doUpdateChefPackage,
  activeChefPackage,
  activeFacility,
  activeClient,
  baseUrl,
}) {
  const [complete, setComplete] = React.useState(false);
  React.useEffect(() => {
    async function completeSignin() {
      const result = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/auth/callback`,
        {
          method: 'POST',
          credentials: 'include',
          body: JSON.stringify({ code: authCodeParam }),
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      const user = await result.json();
      console.log(user);
      ManeAuth.setCurrentUser(user);

      if (localStorage.getItem('redirectURL')) {
        const url = localStorage.getItem('redirectURL');
        localStorage.removeItem('redirectURL');
        doUpdateUrl(url);
      } else {
        doUpdateUrl('/');
      }
    }
    completeSignin();
  }, [authCodeParam]);

  return <div className={classes.root}>Please wait...</div>;
}

AuthCallback.propTypes = {};

export default withStyles(styles, { withTheme: true })(
  connect('selectAuthCodeParam', 'doUpdateUrl', 'selectBaseUrl', AuthCallback),
);
