import { Model, Attr } from 'spraypaint';

import { ApplicationRecord } from './application_record';

@Model()
export default class DashboardStat extends ApplicationRecord {
  static jsonapiType = 'dashboard_stats';
  @Attr() activeWorkstationsByHour: number;
  @Attr() iceByHour: number;
}
