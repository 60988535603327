export default theme => ({
  formControl: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  name: {
    marginRight: 20,

    flexShrink: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  identifier: {
    color: theme.palette.text.secondary,
    flexShrink: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  justify: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  select: {
    overflow: 'hidden',
  },
  spinner: {
    width: [24, '!important'],
    height: [24, '!important'],
    marginRight: 8,
  },
});
