import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Facility from './facility';

@Model()
export default class Subnet extends ApplicationRecord {
  static jsonapiType = 'subnets';
  @Attr() name: string;
  @Attr() vpcId: string;
  @Attr() availabilityZoneId: string;
  @Attr() identifier: string;
  @Attr({ persist: false }) selected: boolean;

  @BelongsTo() facility: Facility;

  constructor(attrs?: Record<string, any>) {
    super(attrs);
    this.selected = false;
  }
}
