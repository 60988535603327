import { Model, Attr, HasMany, BelongsTo, HasOne } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Client from './client';
import CrossAccountRoleInstallation from './cross_account_role_installation';
import Region from './region';

@Model()
export default class Account extends ApplicationRecord {
  static jsonapiType = 'accounts';
  @Attr() name: string;
  @Attr() accountId: string;
  @Attr() email: string;
  @Attr() type: string;
  @Attr() roleArn: string;
  @Attr() kmsKeyId: string;
  @Attr() enableLeastPrivilegeIam: boolean;
  @Attr() enableLambdaPermissionBoundary: boolean;
  @Attr({ persist: false }) selected: boolean;
  @Attr() partOfOrganisation: boolean;

  @BelongsTo() client: Client;
  @HasMany() regions: Region[];
  @HasOne() crossAccountRoleInstallation: CrossAccountRoleInstallation;

  constructor(attrs?: Record<string, any>) {
    super(attrs);
    this.selected = false;
  }
}
