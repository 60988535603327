import React from 'react';
import { Paper, Typography, Link, withStyles } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const styles = theme => ({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    overflow: 'hidden',
  },
  breadcrumb: {
    display: 'flex',
    alignItems: 'center',
  },
});

const Breadcrumbs = ({ children, classes }) => (
  <Paper elevation={0} square className={classes.container}>
    {React.Children.map(children, (child, i) =>
      Array.isArray(children) && i !== children.length - 1 ? (
        <Typography
          variant="body2"
          color="textSecondary"
          className={classes.breadcrumb}
        >
          {child}
          <ChevronRightIcon fontSize="small" color="inherit" />
        </Typography>
      ) : (
        <Typography variant="body2" className={classes.breadcrumb}>
          {React.cloneElement(child, { active: true })}
        </Typography>
      ),
    )}
  </Paper>
);

const Breadcrumb = ({ active, children, to }) =>
  active ? (
    children
  ) : (
    <Link href={to} color="inherit">
      {children}
    </Link>
  );

export default withStyles(styles, { withTheme: true })(Breadcrumbs);

export { Breadcrumb };
