import {
  CircularProgress,
  Divider,
  MenuItem,
  TextField,
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cx from 'classnames';

import styles from './styles';

class ArchBaseSelect extends Component {
  renderValue = value => {
    const {
      classes,
      renderValueName,
      showIdentifier,
      renderValueIdentifier,
      divider,
    } = this.props;
    return (
      <>
        <div className={classes.justify}>
          <span className={classes.name}>{renderValueName(value)}</span>
          {showIdentifier && (
            <span className={classes.identifier}>
              {renderValueIdentifier(value)}
            </span>
          )}
        </div>
      </>
    );
  };

  render() {
    const {
      divider,
      label,
      name,
      options,
      value,
      classes,
      onChange,
      onBlur,
      onFilter,
      InputProps,
      field,
      size,
      className,
      disabled,
      autoFocus,
      loading,
    } = this.props;
    return (
      <TextField
        name={name || field.name}
        select
        label={label}
        variant="outlined"
        className={cx(classes.formControl, className)}
        value={value || field.value || ''}
        onChange={onChange || field.onChange}
        onBlur={onBlur || field.onBlur}
        SelectProps={{
          renderValue: this.renderValue,
          classes: { root: classes.select },
          MenuProps: {
            className: classes.menu,
          },
        }}
        fullWidth
        InputProps={InputProps}
        size={size}
        disabled={disabled}
        autoFocus={autoFocus}
      >
        {loading ? (
          <MenuItem value="none" disabled>
            <CircularProgress className={classes.spinner} />
            Loading...
          </MenuItem>
        ) : (
          options.filter(onFilter).map(t => {
            return [
              <MenuItem key={t.id} value={t}>
                {this.renderValue(t)}
              </MenuItem>,
            ];
          })
        )}
      </TextField>
    );
  }
}

ArchBaseSelect.propTypes = {
  showIdentifier: PropTypes.bool,
  renderValueIdentifier: PropTypes.func,
  renderValueName: PropTypes.func,
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }),
  size: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  onFilter: PropTypes.func,
};

ArchBaseSelect.defaultProps = {
  showIdentifier: true,
  renderValueIdentifier: value => value.identifier,
  renderValueName: value => value.name,
  field: {},
  size: 'medium',
  className: null,
  disabled: false,
  autoFocus: false,
  onFilter: () => true,
  divider: false,
};

export default withStyles(styles, { withTheme: true })(ArchBaseSelect);
