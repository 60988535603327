import {
  CircularProgress,
  Divider,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  withStyles,
} from '@material-ui/core';
import { connect } from 'redux-bundler-react';
import ScheduleIcon from '@material-ui/icons/Schedule';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { baseUrl } from '../../../shared/utils';
import ArchTableToolbarIconButton from '../ArchTableToolbarIconButton';
import AuthorizationCheck from '../../atoms/AuthorizationCheck';
import DeleteFacilityDialog from '../../pages/facility_detail/DeleteFacilityDialog';
import FacilityAvatar from '../../organisms/ArchDrawerMenu/FacilityAvatar';
import InfoTooltip from '../InfoTooltip';
import RebuildDialog from '../../pages/facility_detail/RebuildDialog';

const styles = theme => ({
  avatarAndName: {
    display: 'flex',
    textDecoration: 'none',
    flexGrow: 1,
    alignItems: 'center',
    color: 'inherit',
  },

  menuWithProgressBar: {
    display: 'flex',
    alignItems: 'center',
  },
  progress: {
    marginLeft: 10,
  },
  toolbar: {
    flexShrink: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),

    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
  },
  avatar: {
    marginRight: theme.spacing(1),
  },

  region: {
    verticalAlign: 'bottom',
    marginLeft: -2,
    paddingRight: 4,
  },

  time: {
    verticalAlign: 'bottom',
    marginLeft: theme.spacing(2),
    paddingRight: 4,
  },
  menuAndInfoWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  menuItem: {
    width: 360,
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
});

const FacilityToolbar = ({
  classes,
  activeFacility,
  doCreateWorkstationStatRetrieval,
  doSyncFacility,
  privacyMode,
  baseUrl,
}) => {
  const [dt, setDt] = useState(new Date());

  useEffect(() => {
    let secTimer = setInterval(() => {
      setDt(new Date());
    }, 1000);

    return () => clearInterval(secTimer);
  }, []);
  const [showRebuildDialog, setShowRebuildDialog] = React.useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [busy, setBusy] = React.useState(false);
  const [statsBusy, setStatsBusy] = React.useState(false);
  const [copiedFacilityId, setCopiedFacilityId] = React.useState(false);
  const [copiedShortFacilityId, setCopiedShortFacilityId] = React.useState(
    false,
  );
  const [
    copiedLicenseServerInstanceId,
    setCopiedLicenseServerInstanceId,
  ] = React.useState(false);

  const syncMenuItemText = busy ? 'Sync Requested' : 'Sync';

  const onSyncFacilityClick = async () => {
    setBusy(true);
    await doSyncFacility();
    setBusy(false);
  };
  const sleep = milliseconds => {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  };

  const copyFacilityId = async () => {
    setCopiedFacilityId(true);
    window.navigator.clipboard.writeText(activeFacility.id);
    await sleep(2000);
    setCopiedFacilityId(false);
  };

  const copyLicenseServerInstanceId = async () => {
    setCopiedLicenseServerInstanceId(true);
    console.log(activeFacility.licenseServer);
    window.navigator.clipboard.writeText(
      activeFacility.licenseServer.instanceId,
    );
    await sleep(2000);
    setCopiedLicenseServerInstanceId(false);
  };

  const copyShortFacilityId = async () => {
    setCopiedShortFacilityId(true);
    window.navigator.clipboard.writeText(activeFacility.shortId());
    await sleep(2000);
    setCopiedShortFacilityId(false);
  };

  return (
    <>
      <DeleteFacilityDialog
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
      />
      <RebuildDialog
        open={showRebuildDialog}
        onClose={() => setShowRebuildDialog(false)}
      />
      <Toolbar className={classes.toolbar}>
        <a href={baseUrl} className={classes.avatarAndName}>
          <FacilityAvatar className={classes.avatar} />
          <ListItemText
            className={classes.facilityDetails}
            primary={activeFacility.nameOrFake(privacyMode)}
            primaryTypographyProps={{ variant: 'h6' }}
            secondary={
              <>
                <Typography variant="inherit" color="textSecondary">
                  <LocationOnIcon
                    className={classes.region}
                    fontSize="small"
                    color="secondary"
                  />
                  {activeFacility.region.code}
                </Typography>
                {activeFacility.timeZone && (
                  <Typography variant="inherit" color="textSecondary">
                    <ScheduleIcon className={classes.time} fontSize="small" />
                    {moment(dt)
                      .tz(activeFacility.timeZone)
                      .format('HH:mm')}
                  </Typography>
                )}
              </>
            }
          />
        </a>

        <div className={classes.menuAndInfoWrapper}>
          <AuthorizationCheck
            permissionNames={[
              'facility.update',
              'admin.facility.creation.create',
              'admin.facility.deletion.create',
            ]}
          >
            <ArchTableToolbarIconButton
              onClick={event => setAnchorEl(event.currentTarget)}
            >
              <MoreVertIcon />
            </ArchTableToolbarIconButton>
            <Menu
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              className={classes.menu}
            >
              <MenuItem
                onClick={onSyncFacilityClick}
                className={classes.menuItem}
              >
                {syncMenuItemText}
                <Typography
                  variant="caption"
                  color="textSecondary"
                  className={classes.menuWithProgressBar}
                >
                  Last Sync:{' '}
                  {activeFacility.lastFacilitySync &&
                    activeFacility.lastFacilitySync.isPending() && (
                      <>
                        {moment
                          .utc(activeFacility.lastFacilitySync.createdAt)
                          .local()
                          .format('[Requested] MMM Do HH:mm')}
                        <CircularProgress
                          size={20}
                          className={classes.progress}
                        />
                      </>
                    )}
                  {activeFacility.lastFacilitySync &&
                    activeFacility.lastFacilitySync.isComplete() &&
                    moment
                      .utc(activeFacility.lastFacilitySync.updatedAt)
                      .local()
                      .format('MMM Do HH:mm')}
                  {activeFacility.lastFacilitySync &&
                    activeFacility.lastFacilitySync.isFailed() &&
                    moment
                      .utc(activeFacility.lastFacilitySync.updatedAt)
                      .local()
                      .format('MMM Do HH:mm')}
                  {/* Hello */}
                  {!activeFacility.lastFacilitySync && 'Never'}
                </Typography>
              </MenuItem>

              <MenuItem onClick={copyFacilityId} className={classes.menuItem}>
                {copiedFacilityId ? 'Copied to clipboard' : 'Copy Facility ID'}
              </MenuItem>

              <MenuItem
                onClick={copyShortFacilityId}
                className={classes.menuItem}
              >
                {copiedShortFacilityId
                  ? 'Copied to clipboard'
                  : 'Copy Short Facility ID'}
              </MenuItem>

              {activeFacility.licenseServer && (
                <MenuItem
                  onClick={copyLicenseServerInstanceId}
                  className={classes.menuItem}
                >
                  {copiedLicenseServerInstanceId
                    ? 'Copied to clipboard'
                    : 'Copy License Server ID'}
                </MenuItem>
              )}

              <AuthorizationCheck permissionName="facility.update">
                <MenuItem
                  onClick={async () => {
                    setStatsBusy(true);
                    await doCreateWorkstationStatRetrieval();
                    setStatsBusy(false);
                  }}
                  disabled={statsBusy}
                >
                  Update Stats {statsBusy}
                </MenuItem>
              </AuthorizationCheck>
              <AuthorizationCheck permissionName="facility.update">
                <MenuItem
                  component="a"
                  href={`${baseUrl}/edit`}
                  onClick={() => setAnchorEl(null)}
                >
                  Edit
                </MenuItem>
              </AuthorizationCheck>

              <AuthorizationCheck permissionName="admin.facility.creation.create">
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    setShowRebuildDialog(true);
                  }}
                >
                  Rebuild
                </MenuItem>
              </AuthorizationCheck>
              <AuthorizationCheck permissionName="admin.facility.deletion.create">
                <>
                  <Divider />
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      setShowDeleteDialog(true);
                    }}
                  >
                    Delete
                  </MenuItem>
                </>
              </AuthorizationCheck>
            </Menu>
          </AuthorizationCheck>
          <InfoTooltip>
            <div>{`AWS Account ID: ${activeFacility.account.accountId}`}</div>
            <div>{`Facility Created: ${moment
              .utc(activeFacility.createdAt)
              .local()
              .format('MMM Do HH:mm')}`}</div>

            <div>{`Last Sync: ${activeFacility.lastFacilitySync &&
              moment
                .utc(activeFacility.lastFacilitySync.updatedAt)
                .local()
                .format('MMM Do HH:mm')}`}</div>
          </InfoTooltip>
        </div>
      </Toolbar>
    </>
  );
};
export default connect(
  'selectActiveFacility',
  'selectBaseUrl',
  'doSyncFacility',
  'doCreateWorkstationStatRetrieval',
  'selectPrivacyMode',
  withStyles(styles)(FacilityToolbar),
);
