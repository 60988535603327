import { Helmet } from 'react-helmet';
import { connect } from 'redux-bundler-react';
import {
  createMuiTheme,
  useMediaQuery,
  ThemeProvider,
} from '@material-ui/core';
import { lighten } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import Snowfall from 'react-snowfall';

import ArchPage from './molecules/ArchPage';
import ArchPageWithMenu from './molecules/ArchPage/ArchPageWithMenu';
import AuthorizationCheck from './atoms/AuthorizationCheck';
import BeamerHelmet from './atoms/BeamerHelmet';
import DevelopmentRibbon from './molecules/DevelopmentRibbon';

const Layout = ({
  route: {
    component: PageComponent,
    options: { menu = true },
  },
  themeSetting,
}) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const getPaletteType = () => {
    if (themeSetting === 'system') {
      return prefersDarkMode ? 'dark' : 'light';
    } else {
      return themeSetting || 'light';
    }
  };

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        shadows: [
          'none', // 0
          getPaletteType() === 'light'
            ? '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 2px 0 rgb(63 63 68 / 15%)'
            : '0 0 1px 0 rgb(0 0 0 / 70%), 0 3px 4px -2px rgb(0 0 0 / 50%)',
          '0 0 1px 0 rgb(0 0 0 / 70%), 0 3px 4px -2px rgb(0 0 0 / 50%)', // 2
          '0 0 1px 0 rgb(0 0 0 / 70%), 0 3px 4px -2px rgb(0 0 0 / 50%)', // 3
          '0 0 1px 0 rgb(0 0 0 / 70%), 0 3px 4px -2px rgb(0 0 0 / 50%)', // 4
          '0 0 1px 0 rgba(0,0,0,0.70), 0 4px 6px -2px rgba(0,0,0,0.50)', // 5
          '0 0 1px 0 rgba(0,0,0,0.70), 0 4px 6px -2px rgba(0,0,0,0.50)', // 6
          '0 0 1px 0 rgba(0,0,0,0.70), 0 4px 8px -2px rgba(0,0,0,0.50)', // 7
          '0 0 1px 0 rgba(0,0,0,0.70), 0 5px 8px -2px rgba(0,0,0,0.50)', // 8
        ],
        palette: {
          type: getPaletteType(),
          background: {
            paper: getPaletteType() === 'dark' ? '#282C34' : '#fff',
            default: getPaletteType() === 'dark' ? '#1c1f24' : '#fafafa',
          },
          primary: {
            main: getPaletteType() === 'light' ? '#639' : '#639', // blue[200],
          },
          secondary: {
            main:
              getPaletteType() === 'light'
                ? lighten('#639', 0.1)
                : lighten('#639', 0.2),
          },
        },
      }),
    [themeSetting, prefersDarkMode],
  );
  return menu ? (
    <ThemeProvider theme={theme}>
      <Helmet>
        <html style={`color-scheme: ${getPaletteType()}`} />
      </Helmet>
      <ArchPageWithMenu>
        <AuthorizationCheck permissionNames={['ui.snowfall.view']}>
          <Snowfall snowflakeCount={100} />
        </AuthorizationCheck>
        <Suspense fallback={<div></div>}>
          <PageComponent />
        </Suspense>
      </ArchPageWithMenu>

      <DevelopmentRibbon position="right-bottom">Development</DevelopmentRibbon>
      <BeamerHelmet id="BLfxjJkx31528" />
    </ThemeProvider>
  ) : (
    <ThemeProvider theme={theme}>
      <Helmet>
        <html style={`color-scheme: ${getPaletteType()}`} />
      </Helmet>
      <AuthorizationCheck permissionNames={['ui.snowfall.view']}>
        <Snowfall snowflakeCount={100} />
      </AuthorizationCheck>
      <ArchPage>
        <Suspense fallback={<div></div>}>
          <PageComponent />
        </Suspense>
      </ArchPage>
      <DevelopmentRibbon position="right-bottom">Development</DevelopmentRibbon>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  route: PropTypes.shape({
    component: PropTypes.elementType,
  }).isRequired,
};

export default connect('selectThemeSetting', 'selectRoute', Layout);
