import './logrocket';
import './sentry';
import './App.css';

import { OAuthConfiguration } from './oauth/oauthConfiguration';

import { OAuthClient } from './oauth/oauthClient';

import { Provider, connect } from 'redux-bundler-react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ManeAuth from '../models/mane_auth';

import AuthenticationCheck from './atoms/AuthenticationCheck';
import Layout from './Layout';
import PusherContainer from './atoms/PusherContainer';
import { AuthProvider } from 'oidc-react';

import store from './store';

const ConnectedApp = connect(
  'selectRoute',
  'doFetchAuthenticatedUser',
  'doConfigureMiddleware',
  'doUpdateUrl',
  'selectForcedLogout',
  ({
    route: {
      component: PageComponent,
      options: { requireAuth = true },
    },
    doUpdateUrl,
    doFetchAuthenticatedUser,
    doConfigureMiddleware,
    forcedLogout,
  }) => {
    doConfigureMiddleware();
    const [state, setState] = React.useState({
      isLoaded: false,
      isLoggedIn: false,
      sessionExpired: false,
      oauthClient: new OAuthClient({
        tokenHandlerBaseUrl: process.env.REACT_APP_TOKEN_HANDLER_BASE_URL,
      }),
    });

    // This only runs once to initialize the app
    React.useEffect(() => {
      startup();
      // return () => cleanup();
    }, []);

    /*
     * Create global objects when the app loads
     */
    async function startup() {
      // const storage = new StorageHelper(() => multiTabLogout());
      // await props.viewModel.initialize(storage);
      // window.addEventListener('storage', storage.onChange);

      ManeAuth.setOauthClient(state.oauthClient);
      setState(prevState => ({
        ...prevState,
        isLoaded: false,
        isLoggedIn: false,
        sessionExpired: false,
      }));
    }

    /*
     * Called back from the page load view
     */
    function setIsLoaded() {
      setState(prevState => {
        return {
          ...prevState,
          isLoaded: true,
        };
      });
    }

    /*
     * Called back from the start authentication view
     */
    function setIsLoggedIn() {
      // props.viewModel.storage!.setLoggedOut(false);
      //
      console.log('hellllo');
      doFetchAuthenticatedUser();
      setState(prevState => {
        return {
          ...prevState,
          isLoggedIn: true,
          sessionExpired: false,
        };
      });
    }

    /*
     * Called back from the start authentication view
     */
    function setExpiresAt(expiresAt) {
      // props.viewModel.storage!.setLoggedOut(false);
      ManeAuth.setExpiresAt(expiresAt);
      setState(prevState => {
        return {
          ...prevState,
          expiresAt: expiresAt,
        };
      });
    }
    // const oidcConfig = {
    //   onSignIn: async (user: any) => {
    //     console.log(user);

    //     ManeAuth.setCurrentUser(user);
    //     doUpdateUrl('/');
    //   },
    //   automaticSilentRenew: true,
    //   authority: 'https://app.local.com:4444',
    //   clientId: 'auth-code-client-mane',
    //   responseType: 'code',
    //   redirectUri: 'https://app.local.com:3000/',

    //   scope: 'openid offline_access',
    // };
    return requireAuth ? (
      // <AuthProvider {...oidcConfig}>
      <AuthenticationCheck
        oauthClient={state.oauthClient}
        sessionExpired={state.sessionExpired}
        isLoggedIn={state.isLoggedIn}
        setIsLoaded={setIsLoaded}
        setIsLoggedIn={setIsLoggedIn}
        setExpiresAt={setExpiresAt}
      >
        {/* eslint-disable-next-line no-unused-vars */}
        {user => (
          <PusherContainer>
            <Layout />
          </PusherContainer>
        )}
      </AuthenticationCheck>
    ) : (
      // </AuthProvider>
      // <PusherContainer>
      <PageComponent />
      // </PusherContainer>
    );
  },
);

const App = () => {
  return (
    <Provider store={store}>
      <ConnectedApp />
    </Provider>
  );
};

export default App;
