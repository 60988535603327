import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { connect } from 'redux-bundler-react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import React from 'react';
import * as yup from 'yup';

import ConfirmationDialog from './ConfirmationDialog';

const styles = theme => ({
  inProgress: {
    display: 'flex',
    alignItems: 'center',
  },

  spinner: {
    marginLeft: theme.spacing(1),
  },
  requirement: {
    marginTop: theme.spacing(1),
    display: 'flex',
    color: theme.palette.text.secondary,

    alignItems: 'center',
  },
  requirementIcon: {
    marginRight: theme.spacing(1),
  },
});

const SplitTestDialog = ({
  classes,
  open,
  onClose,
  doChangePassword,
  activeClient,
}) => {
  const [password, setPassword] = React.useState('');
  const [passwordConfirmation, setPasswordConfirmation] = React.useState('');
  const [passwordMatches, setPasswordMatches] = React.useState(false);
  const [busy, setBusy] = React.useState(false);
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [message, setMessage] = React.useState(false);

  const handleConfirm = async (values, { setErrors, setSubmitting }) => {
    var now = new Date();
    var expires = now.getTime() + 1000 * 3600 * 24 * 365;
    now.setTime(expires);

    document.cookie = `nf_ab=${
      values.cookie
    }; expires=${now.toUTCString()}; path=/`;

    setBusy(false);
    setShowConfirmation(true);
    setMessage('Please reload the page for the change to take effect');
    onClose();
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  React.useEffect(() => {
    const timer = setTimeout(() => {
      open && inputEl.current.focus();
    }, 100);
    return () => clearTimeout(timer);
  }, [open]);

  const inputEl = React.useRef(null);

  const cookie = document.cookie
    .split('; ')
    .find(row => row.startsWith('nf_ab='));
  const cookieValue = cookie ? cookie.split('=')[1] : null;

  return (
    <>
      <ConfirmationDialog
        title="Split Test"
        open={showConfirmation}
        onClose={handleCloseConfirmation}
        message={message}
      />
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        fullWidth
        aria-labelledby="confirmation-dialog-title"
        open={open}
      >
        <Formik
          enableReinitialize
          initialValues={{
            cookie: cookieValue || '',
          }}
          onSubmit={handleConfirm}
          validateOnMount
        >
          {({ isValid, values }) => {
            return (
              <Form>
                <DialogTitle>Split Test Cookie Value</DialogTitle>
                <DialogContent>
                  <div>
                    <Field
                      name="cookie"
                      fullWidth
                      component={TextField}
                      margin="normal"
                      variant="outlined"
                      label="Split Test Cookie Value"
                      autofocus
                      inputRef={inputEl}
                    />
                  </div>
                  {busy && (
                    <Typography variant="body" className={classes.inProgress}>
                      please wait...{' '}
                      <CircularProgress size={20} className={classes.spinner} />
                    </Typography>
                  )}
                </DialogContent>

                <DialogActions>
                  <Button onClick={onClose} color="primary">
                    Cancel
                  </Button>
                  <Button
                    color="secondary"
                    type="submit"
                    disabled={!isValid || busy}
                  >
                    Update
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </>
  );
};

export default connect(
  'doChangePassword',
  'selectActiveClient',
  withStyles(styles)(SplitTestDialog),
);
