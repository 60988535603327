import React, { Component } from 'react';

import ArchDrawerMenu from '../../organisms/ArchDrawerMenu';
import ArchPage from '.';

class ArchPageWithMenu extends Component {
  render() {
    return <ArchPage drawerMenu={ArchDrawerMenu} {...this.props} />;
  }
}

export default ArchPageWithMenu;
