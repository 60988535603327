import { Model, BelongsTo, Attr } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import ChefPackage from './chef_package';

@Model()
export default class ChefPackageSync extends ApplicationRecord {
  static jsonapiType = 'chef_package_syncs';

  @Attr() state: string;

  @Attr() createdAt: string;
  @Attr() updatedAt: string;
  @BelongsTo() chefPackage: ChefPackage;

  isComplete() {
    return this.state === 'completed';
  }

  isPending() {
    return this.state === 'pending';
  }

  isStarted() {
    return this.state === 'started';
  }

  isFailed() {
    return this.state === 'failed';
  }
}
