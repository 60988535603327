import { Paper, Typography, withStyles } from '@material-ui/core';
import { connect } from 'redux-bundler-react';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import React from 'react';

import { Breadcrumb } from '../Breadcrumbs';
import ClientPrivacyMask from '../../atoms/ClientPrivacyMask';

const styles = theme => ({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    overflow: 'hidden',
  },
  breadcrumb: {
    display: 'flex',
    alignItems: 'center',
  },
});

const FacilityBreadcrumbs = ({
  classes,
  activeClient,
  activeFacility,
  baseUrl,
  privacyMode,
  children,
}) => {
  return (
    <Paper elevation={0} square className={classes.container}>
      <Typography
        variant="body2"
        color="textSecondary"
        className={classes.breadcrumb}
      >
        <Breadcrumb to={`/clients/${activeClient.id}`} chevron>
          <ClientPrivacyMask client={activeClient} />
        </Breadcrumb>

        <ChevronRightIcon fontSize="small" color="inherit" />
      </Typography>

      <Typography
        variant="body2"
        color="textSecondary"
        className={classes.breadcrumb}
      >
        <Breadcrumb to={`${baseUrl}`} chevron>
          {activeFacility.nameOrFake(privacyMode)}
        </Breadcrumb>
        {children && <ChevronRightIcon fontSize="small" color="inherit" />}
      </Typography>
      {React.Children.map(children, (child, i) =>
        Array.isArray(children) && i !== children.length - 1 ? (
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.breadcrumb}
          >
            {child}
            <ChevronRightIcon fontSize="small" color="inherit" />
          </Typography>
        ) : (
          <Typography variant="body2" className={classes.breadcrumb}>
            {React.cloneElement(child, { active: true })}
          </Typography>
        ),
      )}
    </Paper>
  );
};

export default connect(
  'selectActiveClient',
  'selectActiveFacility',
  'selectBaseUrl',
  'selectPrivacyMode',
  withStyles(styles)(FacilityBreadcrumbs),
);
