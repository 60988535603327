import { LinearProgress, Toolbar, Typography } from '@material-ui/core';
import { lighten, makeStyles, darken } from '@material-ui/core/styles';

import { fade } from '@material-ui/core/styles/colorManipulator';
import React from 'react';
import classnames from 'classnames';

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: '#3f51b5',
        },
  spacer: {},
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {},
  progress: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 4,
  },
}));

export default function ArchTableToolbar(props) {
  const classes = useToolbarStyles();
  const { numSelected, title, loading, children } = props;

  return (
    <Toolbar
      className={classnames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            {title}
          </Typography>
        )}
      </div>

      <div className={classes.spacer} />
      <div className={classes.actions}>{children}</div>
      {loading && <LinearProgress className={classes.progress} />}
    </Toolbar>
  );
}
