import { Attr, BelongsTo, HasMany, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Client from './client';
import Group from './group';
import TeamGroupAssignment from './team_group_assignment';
import TeamMembership from './team_membership';
import User from './user';

@Model()
export default class Team extends ApplicationRecord {
  static jsonapiType = 'teams';
  @Attr() name: string;
  @Attr() createdAt: string;
  @Attr() updatedAt: string;
  @Attr() userCount: number;
  @Attr({ persist: false }) userAvatarSummary: object[];
  @Attr({ persist: false }) selected: boolean;

  @BelongsTo() client: Client;
  @HasMany() users: User[];
  @HasMany() teamMemberships: TeamMembership[];
  @HasMany() teamGroupAssignments: TeamGroupAssignment[];
  @HasMany() groups: Group[];

  constructor(attrs?: Record<string, any>) {
    super(attrs);
    this.selected = false;
  }

  isEmpty() {
    return this.users.length === 0;
  }

  hasGroups() {
    return this.groups.length > 0;
  }
}
