import { Auth } from 'aws-amplify';
import { createSelector } from 'redux-bundler';
import { MiddlewareStack } from 'spraypaint';
import { ApplicationRecord } from '../models/application_record';
import LogRocket from 'logrocket';

import moment from 'moment-timezone';
import ManeAuth from '../models/mane_auth';

export default {
  name: 'auth',
  getReducer: () => {
    const initialData = {
      user: null,
      lastRefresh: null,
      middlewareConfigured: false,
      forcedLogout: false,
    };

    return (state = initialData, { type, payload }) => {
      if (type === 'SET_FORCED_LOGOUT') {
        return {
          ...state,
          ...{
            forcedLogout: true,
          },
        };
      }

      if (type === 'CONFIGURE_MIDDLEWARE') {
        return {
          ...state,
          ...{
            middlewareConfigured: true,
          },
        };
      }

      if (type === 'SET_CURRENT_USER') {
        return {
          ...state,
          ...{
            user: payload.user,
            session: payload.user && payload.user.signInUserSession,
            lastRefresh: Date.now(),
          },
        };
      }

      if (type === 'SET_CURRENT_SESSION') {
        return {
          ...state,
          ...{
            session: payload.session,
            lastRefresh: Date.now(),
          },
        };
      }

      return state;
    };
  },
  doSetCurrentUser: user => ({ dispatch }) => {
    dispatch({
      type: 'SET_CURRENT_USER',
      payload: {
        user,
      },
    });
    if (user?.attributes?.email) {
      localStorage.setItem('userEmail', user?.attributes?.email);
      localStorage.setItem('provider', user?.attributes?.provider);
      LogRocket.identify(user?.attributes?.email);
    }
  },
  doLogout: (forced: true) => ({ dispatch, store }) => {
    localStorage.removeItem('userEmail');
    store.doSetCurrentUser(null);
    if (forced) {
      dispatch({
        type: 'SET_FORCED_LOGOUT',
      });
      // window.location = '/';
      //
      return;
    }

    ManeAuth.logout();
    console.log('Updating Local Storage');

    localStorage.setItem('logout', Date.now());
  },
  doFetchUser: () => async ({ dispatch, store, getState }) => {
    try {
      const session = await Auth.currentSession();

      dispatch({
        type: 'SET_CURRENT_SESSION',
        payload: {
          session,
        },
      });
    } catch (err) {
      console.log(err);
    }
  },
  selectCurrentUser: state => state.auth.user,
  selectCurrentSession: state => state.auth.session,
  selectJwtToken: createSelector(
    'selectCurrentSession',
    session => session && session.idToken.jwtToken,
  ),
  selectLastRefresh: state => state.auth.lastRefresh,
  selectForcedLogout: state => state.auth.forcedLogout,

  selectAuthCodeParam: createSelector(
    'selectQueryObject',
    queryParams => queryParams.code,
  ),

  doConfigureMiddleware: () => ({ dispatch, store, getState }) => {
    const state = getState();
    if (!state.auth.middlewareConfigured) {
      const middleware = new MiddlewareStack();
      middleware.beforeFilters.push(async (url, options) => {
        try {
          await ManeAuth.refresh();
          options.credentials = 'include';
          options.headers['x-bff-csrf'] = ManeAuth.getCsrf();
        } catch (err) {
          console.log(err);

          store.doLogout({ forced: true });

          // await ManeAuth.logout(false);
        }
      });

      middleware.afterFilters.push((response, json) => {
        if (response.status === 401) {
          console.log('User is signed out');

          store.doLogout({ forced: true });
          throw 'abort';
        }
      });

      ApplicationRecord.middlewareStack = middleware;
      dispatch({
        type: 'CONFIGURE_MIDDLEWARE',
      });
    }
  },
};
