import { createSelector } from 'redux-bundler';
import { snakeCase } from 'lodash';

import Group from '../models/group';
import Team from '../models/team';
import TeamGroupAssignment from '../models/team_group_assignment.ts';
import Workstation from '../models/workstation';
import ParsecGroupQuery from '../models/parsec_group_query';

export default {
  name: 'groups',
  getReducer: () => {
    const initialData = {
      data: null,
      sortDirection: 'ASC',
      sortBy: 'name',
      loading: false,
      activeGroup: null,
      loadingActiveGroup: null,
      scope: 'client',
    };

    return (state = initialData, { type, payload }) => {
      if (type === 'FETCH_GROUP_START') {
        return { ...state, loadingActiveGroup: true };
      }
      if (type === 'FETCH_GROUP_SUCCESS') {
        return {
          ...state,
          loadingActiveGroup: false,
          activeGroup: payload.result,
        };
      }
      if (type === 'FETCH_GROUPS_START') {
        return { ...state, loading: true };
      }
      if (type === 'FETCH_GROUPS_SUCCESS') {
        return {
          ...state,
          loading: false,
          data: payload.results,
          scope: payload.scope,
        };
      }
      if (type === 'CHANGE_GROUP_SORT') {
        return {
          ...state,
          sortDirection: payload.sortDirection,
          sortBy: payload.sortBy,
        };
      }
      if (type === 'ADD_GROUP' && state.data) {
        return { ...state, data: [...state.data, payload.group] };
      }
      if (type === 'UPDATE_GROUP') {
        if (state.data) {
          const index = state.data.findIndex(u => u.id === payload.group.id);
          const { group } = payload;
          return {
            ...state,
            data: state.data
              .slice(0, index)
              .concat([group])
              .concat(state.data.slice(index + 1)),
            activeGroup:
              state.activeGroup.id === payload.group.id
                ? group
                : state.activeGroup,
          };
        }

        return {
          ...state,
          activeGroup: payload.group,
        };
      }
      if (type === 'TOGGLE_GROUP_SELECTION') {
        const index = state.data.findIndex(u => u.id === payload.group.id);
        const { group } = payload;
        group.selected = !group.selected;
        group.reset();
        return {
          ...state,
          data: state.data
            .slice(0, index)
            .concat([group])
            .concat(state.data.slice(index + 1)),
        };
      }
      if (type === 'REMOVE_GROUP') {
        const index = state.data.findIndex(u => u.id === payload.group.id);
        return {
          ...state,
          data: state.data.slice(0, index).concat(state.data.slice(index + 1)),
        };
      }

      if (type === 'RESET_CLIENT') {
        return {
          ...state,
          data: null,
        };
      }

      if (type === 'RESET_ACTIVE_FACILITY') {
        return {
          ...state,
          data: null,
        };
      }

      if (type === 'RESET_ACTIVE_GROUP') {
        return {
          ...state,
          activeGroup: null,
        };
      }

      if (type === 'RESET_GROUPS') {
        return {
          ...state,
          data: null,
          scope: null,
        };
      }

      if (type === 'RESET_ACTIVE_FACILITY') {
        return {
          ...state,
          data: null,
        };
      }

      return state;
    };
  },

  doResetGroups: () => ({ dispatch }) => {
    dispatch({ type: 'RESET_GROUPS' });
  },

  doResetActiveGroup: () => ({ dispatch }) => {
    dispatch({ type: 'RESET_ACTIVE_GROUP' });
  },

  doFetchGroupsForFacility: () => async ({ dispatch, getState, store }) => {
    const state = getState();
    const { sortBy } = state.groups;
    const { sortDirection } = state.groups;

    const facility = store.selectActiveFacility(state);

    dispatch({ type: 'FETCH_GROUPS_START' });
    const response = await Group.order({
      [snakeCase(sortBy)]: sortDirection.toLowerCase(),
    })
      .where({ facility_id: facility.id })
      .includes('facility')
      .all();
    dispatch({
      type: 'FETCH_GROUPS_SUCCESS',
      payload: { results: response.data, scope: 'facility' },
    });
  },

  doFetchGroups: () => async ({ dispatch, getState, store }) => {
    const state = getState();
    const { sortBy } = state.groups;
    const { sortDirection } = state.groups;

    const client = store.selectActiveClient(state);

    dispatch({ type: 'FETCH_GROUPS_START' });
    const response = await Group.order({
      [snakeCase(sortBy)]: sortDirection.toLowerCase(),
    })
      .where({ client_id: client.id })
      .includes('facility')
      .all();
    dispatch({
      type: 'FETCH_GROUPS_SUCCESS',
      payload: { results: response.data, scope: 'client' },
    });
  },

  doFetchActiveGroup: groupId => async ({ dispatch }) => {
    dispatch({ type: 'FETCH_GROUP_START' });

    const response = await Group.includes([
      'teams',
      { workstations: ['last_login', 'last_connection'] },
    ])
      .order('workstations.name')
      .find(groupId);
    dispatch({
      type: 'FETCH_GROUP_SUCCESS',
      payload: { result: response.data },
    });
  },

  doChangeGroupSort: ({ sortDirection, sortBy }) => async ({
    dispatch,
    store,
  }) => {
    dispatch({ type: 'CHANGE_GROUP_SORT', payload: { sortDirection, sortBy } });
    store.doFetchGroups();
  },

  doToggleGroupSelection: group => ({ dispatch }) => {
    dispatch({ type: 'TOGGLE_GROUP_SELECTION', payload: { group } });
  },

  doCreateGroup: values => async ({ dispatch, store, state }) => {
    const facility = store.selectActiveFacility(state);
    const group = new Group({
      name: values.name,
      facility,
      parsecTeamGroupId: values.parsecTeamGroup && values.parsecTeamGroup.id,
      parsecTeamGroupName:
        values.parsecTeamGroup && values.parsecTeamGroup.name,
    });

    const success = await group.save({
      with: ['facility.id'],
    });
    if (success) {
      dispatch({ type: 'ADD_GROUP', payload: { group } });
    }
    return group;
  },

  doDestroyGroup: group => async ({ dispatch }) => {
    await group.destroy();
    dispatch({ type: 'REMOVE_GROUP', payload: { group } });
  },

  doUpdateGroup: values => async ({ dispatch, store }) => {
    const {
      groups: { activeGroup },
    } = store.getState();

    const group = activeGroup.dup();
    const { parsecTeamGroup, ...nextValues } = values;
    group.assignAttributes({
      ...nextValues,
      parsecTeamGroupId: parsecTeamGroup?.id,
      parsecTeamGroupName: parsecTeamGroup?.name,
    });

    const success = await group.save();
    if (success) {
      dispatch({
        type: 'UPDATE_GROUP',
        payload: { group },
      });
    }
    return group;
  },

  doAddTeamsToGroup: (teams, group) => async ({ store, getState }) => {
    const nextGroup = group.dup();

    const teamGroupAssignments = teams.map(
      team => new TeamGroupAssignment({ team }),
    );

    nextGroup.teamGroupAssignments = [
      ...nextGroup.teamGroupAssignments,
      ...teamGroupAssignments,
    ];

    await nextGroup.save({
      with: [{ teamGroupAssignments: 'team.id' }],
    });

    const state = getState();
    const activeGroup = store.selectActiveGroup(state);
    if (activeGroup) {
      store.doFetchActiveGroup(activeGroup.id);
    }
  },

  doRemoveTeamFromGroup: (team, group) => async ({
    dispatch,
    store,
    getState,
  }) => {
    let response = await Team.includes([
      'users',
      { groups: 'facility' },
      { team_group_assignments: 'group' },
    ]).find(team.id);
    const nextTeam = response.data;

    nextTeam.teamGroupAssignments.forEach(tga => {
      if (tga.group.id === group.id) {
        tga.isMarkedForDestruction = true;
      }
    });

    const success = await nextTeam.save({
      with: [{ teamGroupAssignments: 'group.id' }, 'groups'],
    });

    response = await Team.includes([
      'users',
      { groups: 'facility' },
      { team_group_assignments: 'group' },
    ]).find(nextTeam.id);
    const reloadedTeam = response.data;

    if (success) {
      dispatch({
        type: 'UPDATE_TEAM',
        payload: { team: reloadedTeam },
      });

      const state = getState();
      const activeGroup = store.selectActiveGroup(state);
      if (activeGroup) {
        store.doFetchActiveGroup(activeGroup.id);
      }
    }
    return reloadedTeam;
  },

  doRemoveWorkstationFromGroup: (workstation, group) => async ({
    store,
    getState,
  }) => {
    const response = await Workstation.includes([
      { workstation_group_assignments: 'group' },
    ]).find(workstation.id);
    const nextWorkstation = response.data;

    nextWorkstation.workstationGroupAssignments.forEach(wga => {
      if (wga.group.id === group.id) {
        wga.isMarkedForDestruction = true;
      }
    });

    const success = await nextWorkstation.save({
      with: [{ workstationGroupAssignments: 'group.id' }, 'groups'],
    });

    if (success) {
      const state = getState();
      const activeGroup = store.selectActiveGroup(state);
      if (activeGroup) {
        store.doFetchActiveGroup(activeGroup.id);
      }

      const activeWorkstation = store.selectActiveWorkstation(state);
      if (activeWorkstation) {
        store.doFetchWorkstation(activeWorkstation.id);
      }
    }
  },

  selectGroupSortBy: state => state.groups.sortBy,
  selectGroupSortDirection: state => state.groups.sortDirection,
  selectGroupLoading: state => state.groups.loading,
  selectGroupRaw: state => state.groups,
  selectGroupData: state => state.groups.data || [],
  selectSelectedGroupCount: createSelector(
    'selectGroupData',
    teamData => teamData.filter(u => u.selected).length,
  ),
  selectSelectedGroups: createSelector('selectGroupData', teamData =>
    teamData.filter(u => u.selected),
  ),

  selectActiveGroupId: createSelector(
    'selectRouteParams',
    routeParams => routeParams.groupId,
  ),
  selectActiveGroup: createSelector(
    'selectGroupRaw',
    teamData => teamData.activeGroup,
  ),
  reactShouldFetchGroups: createSelector(
    'selectRouteApis',
    'selectGroupRaw',
    'selectCurrentUser',
    'selectActiveClient',
    (apis, groupData, currentUser, activeClient) => {
      const wantsGroups = apis.includes('groups');

      if (
        !wantsGroups ||
        groupData.loading ||
        groupData.data ||
        !currentUser ||
        !activeClient
      ) {
        return false;
      }
      return { actionCreator: 'doFetchGroups' };
    },
  ),

  doCreateParsecGroupQuery: facility => async () => {
    const pgq = new ParsecGroupQuery({
      facility,
    });
    await pgq.save({ with: ['facility.id'] });

    return pgq;
  },

  reactShouldFetchGroupsForFacilities: createSelector(
    'selectRouteApis',
    'selectGroupRaw',
    'selectCurrentUser',
    'selectActiveFacility',
    (apis, groupData, currentUser, activeFacility) => {
      const wantsGroups = apis.includes('groups_for_facility');

      if (
        !wantsGroups ||
        groupData.loading ||
        groupData.data ||
        !currentUser ||
        !activeFacility
      ) {
        return false;
      }
      return { actionCreator: 'doFetchGroupsForFacility' };
    },
  ),

  reactShouldFetchActiveGroup: createSelector(
    'selectRouteParams',
    'selectPathname',
    'selectActiveGroup',
    'selectGroupRaw',
    (routeParams, pathname, activeGroup, teamData) => {
      if (
        !pathname.includes('/groups') ||
        !routeParams.groupId ||
        teamData.loadingActiveGroup
      ) {
        return null;
      }
      if (activeGroup && activeGroup.id === routeParams.groupId) {
        return null;
      }

      return {
        actionCreator: 'doFetchActiveGroup',
        args: [routeParams.groupId],
      };
    },
  ),

  reactScopeChange: createSelector(
    'selectRouteApis',
    'selectGroupRaw',
    (apis, groupData) => {
      let scope;
      if (apis.includes('groups')) scope = 'client';

      if (apis.includes('groups_for_facility')) scope = 'facility';
      if (groupData.scope && groupData.scope !== scope) {
        return { actionCreator: 'doResetGroups' };
      }

      return null;
    },
  ),

  reactShouldResetActiveGroup: createSelector(
    'selectActiveGroupId',
    'selectActiveGroup',
    (activeGroupId, activeGroup) => {
      if (!activeGroup) return null;
      if (activeGroup.id !== activeGroupId) {
        return { actionCreator: 'doResetActiveGroup' };
      }

      return null;
    },
  ),
};
