import { Container, withStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { connect } from 'redux-bundler-react';
import React, { Component } from 'react';

import { Breadcrumb } from '../../organisms/Breadcrumbs';
import ArchPageContent from '../../molecules/ArchPageContent';
import ArchTable from '../../organisms/ArchTable';
import ArchTableColumn from '../../molecules/ArchTableColumn';
import ArchTableToolbar from '../../molecules/ArchTableToolbar';
import ArchTableToolbarActionButton from '../../molecules/ArchTableToolbarActionButton';
import FacilityBreadcrumbs from '../../organisms/FacilityBreadcrumbs';
import PageTitle from '../../atoms/PageTitle';

class Templates extends Component {
  sort = ({ sortDirection, sortBy }) => {
    const { doChangeTemplatesSort } = this.props;
    doChangeTemplatesSort({ sortDirection, sortBy });
  };

  href = ({ rowData }) => {
    const { baseUrl, showRender } = this.props;
    return `${baseUrl}/templates/${rowData.id}`;
  };

  render() {
    const {
      classes,
      templates,
      templatesLoading,
      templatesSortDirection,
      templatesSortBy,
      baseUrl,
      activeClient,
      activeFacility,
    } = this.props;

    if (!activeClient || !activeFacility) {
      return (
        <ArchPageContent>
          <Container>
            <Skeleton />
          </Container>
        </ArchPageContent>
      );
    }
    const rows = [];
    return (
      <>
        <PageTitle>Templates</PageTitle>
        <FacilityBreadcrumbs>
          <Breadcrumb to={`${baseUrl}/templates`}>Templates</Breadcrumb>
        </FacilityBreadcrumbs>
        <ArchPageContent flex fullWidth>
          <ArchTable
            title="Templates"
            rowCount={templates.length}
            rowGetter={({ index }) => templates[index]}
            loading={templatesLoading}
            sort={this.sort}
            sortDirection={templatesSortDirection}
            sortBy={templatesSortBy}
            rowHeight={56}
            renderToolbar={() => (
              <ArchTableToolbar title="Templates" loading={templatesLoading}>
                {/* <AuthorizationCheck permissionName="image.pipeline.create"> */}
                <ArchTableToolbarActionButton
                  variant="contained"
                  color="primary"
                  component="a"
                  href={`${baseUrl}/templates/new`}
                >
                  Add Template
                </ArchTableToolbarActionButton>
                {/* </AuthorizationCheck> */}
              </ArchTableToolbar>
            )}
          >
            {/* <ArchTableCheckboxColumn width={50} flexShrink={0} /> */}
            <ArchTableColumn
              dataKey="name"
              width={550}
              label="Name"
              flexGrow={1}
              columnData={{ href: this.href }}
            />
            {/* <ArchTableColumn */}
            {/*   dataKey="identifier" */}
            {/*   width={300} */}
            {/*   label="ID" */}
            {/*   cellDataGetter={({ rowData }) => <>{rowData.identifier}</>} */}
            {/* /> */}

            <ArchTableColumn
              dataKey="imagePipeline"
              width={200}
              label="Image Pipeline"
              cellDataGetter={({ rowData }) =>
                rowData.imagePipeline && rowData.imagePipeline.name
              }
              columnData={{ href: this.href }}
            />

            <ArchTableColumn
              dataKey="maintenancWindow"
              width={200}
              label="Mnt. Window"
              flexGrow={1}
              cellDataGetter={({ rowData }) => rowData.maintenanceWindow || '-'}
              columnData={{ href: this.href }}
            />
            <ArchTableColumn
              dataKey="instanceType"
              width={200}
              label="Instance Type"
              cellDataGetter={({ rowData }) =>
                rowData.instanceType && rowData.instanceType.identifier
              }
              columnData={{ href: this.href }}
            />

            <ArchTableColumn
              width={200}
              dataKey="render"
              label="Type"
              cellDataGetter={({ rowData }) =>
                rowData.render ? 'Render' : 'Workstation'
              }
              columnData={{ href: this.href }}
            />
          </ArchTable>
        </ArchPageContent>
      </>
    );
  }
}

export default connect(
  'selectTemplates',
  'selectTemplatesLoading',
  'selectTemplatesSortBy',
  'selectTemplatesSortDirection',
  'doChangeTemplatesSort',
  'selectBaseUrl',
  'selectActiveClient',
  'selectActiveFacility',
  withStyles({}, { withTheme: true })(Templates),
);
