export default {
  name: 'preferences',
  getReducer: () => {
    const initialData = {
      themeSetting: 'light',
      showMetrics: false,
      privacyMode: false,
      displayMode: 'normal',
      disableAutoLogout: false,
    };

    return (state = initialData, { type, payload }) => {
      if (type === 'SET_THEME_SETTING') {
        return {
          ...state,
          ...{
            themeSetting: payload.themeSetting,
          },
        };
      }

      if (type === 'SET_DISPLAY_MODE') {
        return {
          ...state,
          ...{
            displayMode: payload.displayMode,
          },
        };
      }

      if (type === 'SET_PRIVACY_MODE') {
        return {
          ...state,
          ...{
            privacyMode: payload.privacyMode,
          },
        };
      }

      if (type === 'SET_SHOW_METRICS') {
        return {
          ...state,
          ...{
            showMetrics: payload.showMetrics,
          },
        };
      }

      if (type === 'SET_DISABLE_AUTO_LOGOUT') {
        return {
          ...state,
          ...{
            disableAutoLogout: payload.disableAutoLogout,
          },
        };
      }

      if (type === 'SET_METRICS_PANEL') {
        return {
          ...state,
          ...{
            showMetrics: payload.metricsPanel,
          },
        };
      }

      return state;
    };
  },

  doSetDisableAutoLogout: disableAutoLogout => ({ dispatch }) => {
    dispatch({
      type: 'SET_DISABLE_AUTO_LOGOUT',
      payload: {
        disableAutoLogout,
      },
    });
  },

  doSetDisplayMode: displayMode => ({ dispatch }) => {
    localStorage.setItem('displayMode', displayMode);
    dispatch({
      type: 'SET_DISPLAY_MODE',
      payload: {
        displayMode,
      },
    });
  },

  doSetThemeSetting: themeSetting => ({ dispatch }) => {
    localStorage.setItem('themeSetting', themeSetting);
    dispatch({
      type: 'SET_THEME_SETTING',
      payload: {
        themeSetting,
      },
    });
  },

  doSetPrivacyMode: privacyMode => ({ dispatch }) => {
    localStorage.setItem('privacyMode', privacyMode);
    dispatch({
      type: 'SET_PRIVACY_MODE',
      payload: {
        privacyMode,
      },
    });
  },

  doSetMetricsPanel: metricsPanel => ({ dispatch }) => {
    localStorage.setItem('metricsPanel', metricsPanel);
    dispatch({
      type: 'SET_METRICS_PANEL',
      payload: {
        metricsPanel,
      },
    });
  },

  selectThemeSetting: state => {
    return (
      localStorage.getItem('themeSetting') || state.preferences.themeSetting
    );
  },

  selectDisplayMode: state => {
    return localStorage.getItem('displayMode') || state.preferences.displayMode;
  },

  doSetShowMetrics: showMetrics => ({ dispatch }) => {
    localStorage.setItem('showMetrics', showMetrics);
    dispatch({
      type: 'SET_SHOW_METRICS',
      payload: {
        showMetrics,
      },
    });
  },

  selectPrivacyMode: state => {
    const value = localStorage.getItem('privacyMode');
    if (value) {
      return value === 'true';
    }
    return state.preferences.privacyMode;
  },

  selectShowMetrics: state => {
    const value = localStorage.getItem('showMetrics');
    if (value) {
      return value === 'true';
    }
    return state.preferences.showMetrics;
  },

  selectDisableAutoLogout: state => {
    return state.preferences.disableAutoLogout;
  },

  selectMetricsPanel: state => {
    return (
      localStorage.getItem('metricsPanel') || state.preferences.metricsPanel
    );
  },
};
