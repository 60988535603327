import { createSelector } from 'redux-bundler';
import { snakeCase } from 'lodash';

import Image from '../models/image';
import ImageApproval from '../models/imageApproval';

export default {
  name: 'images',
  getReducer: () => {
    const initialData = {
      loading: false,
      items: null,
      sortDirection: 'ASC',
      sortBy: 'name',
      loadingOverrideImage: false,
      overrideImage: null,
      filterValues: {},
    };
    // eslint-disable-next-line no-unused-vars
    return (state = initialData, { type, payload }) => {
      if (type === 'FETCH_IMAGES') {
        return { ...state, loading: true };
      }
      if (type === 'FETCH_IMAGES_SUCCESS') {
        return {
          ...state,
          loading: false,
          items: payload.result,
        };
      }
      if (type === 'CHANGE_IMAGE_SORT') {
        return {
          ...state,
          sortDirection: payload.sortDirection,
          sortBy: payload.sortBy,
        };
      }
      if (type === 'FETCH_OVERRIDE_IMAGE_START') {
        return { ...state, loadingOverrideImage: true };
      }
      if (type === 'FETCH_OVERRIDE_IMAGE_SUCCESS') {
        return {
          ...state,
          loadingOverrideImage: false,
          overrideImage: payload.result,
        };
      }

      if (type === 'FILTER_IMAGES_BY_PLATFORM') {
        return {
          ...state,
          filters: {
            ...state.filters,
            platform: payload,
          },
          filterValues: {
            ...state.filterValues,
            platform: payload,
          },
        };
      }
      return state;
    };
  },

  doFilterImagesByPlatform: platform => ({ dispatch, store }) => {
    dispatch({
      type: 'FILTER_IMAGES_BY_PLATFORM',
      payload: platform,
    });

    store.doFetchImages();
  },

  doFetchImages: () => async ({ dispatch, getState, store }) => {
    const state = getState();

    const { sortBy } = state.images;
    const { sortDirection } = state.images;

    const client = store.selectActiveClient(state);
    dispatch({ type: 'FETCH_IMAGES' });
    const scope = Image.where({
      kept: true,
      client_id: client.id,
    })
      .where(state.images.filters)
      .order({
        [snakeCase(sortBy)]: sortDirection.toLowerCase(),
      })
      .includes(['image_pipeline', 'image_pipeline_sync']);

    const response = await scope.all();

    dispatch({
      type: 'FETCH_IMAGES_SUCCESS',
      payload: { result: response.data },
    });
  },

  doChangeImageSort: ({ sortDirection, sortBy }) => async ({
    dispatch,
    store,
  }) => {
    dispatch({ type: 'CHANGE_IMAGE_SORT', payload: { sortDirection, sortBy } });
    store.doFetchImages();
  },

  doFetchOverrideImage: () => async ({ dispatch, getState, store }) => {
    const state = getState();
    const overrideImageId = store.selectOverrideImageId(state);

    dispatch({ type: 'FETCH_OVERRIDE_IMAGE_START' });

    const response = await Image.includes([]).find(overrideImageId);
    dispatch({
      type: 'FETCH_OVERRIDE_IMAGE_SUCCESS',
      payload: { result: response.data },
    });
  },

  doCreateImageApproval: image => async ({ getState, store }) => {
    const state = getState();

    const imageApproval = new ImageApproval({
      image,
    });

    await imageApproval.save({
      with: ['image.id'],
    });
  },

  reactShouldFetchImages: createSelector(
    'selectRouteApis',
    'selectImagesRaw',
    'selectCurrentUser',
    'selectActiveClient',
    (apis, imagesRaw, currentUser, activeClient) => {
      const wantsImages = apis.includes('images');
      if (
        !wantsImages ||
        imagesRaw.loading ||
        imagesRaw.items ||
        !currentUser ||
        !activeClient
      ) {
        return false;
      }
      return { actionCreator: 'doFetchImages' };
    },
  ),

  reactShouldFetchOverrideImage: createSelector(
    'selectOverrideImageId',
    'selectOverrideImage',
    'selectImagesRaw',
    (overrideImageId, overrideImage, imagesRaw) => {
      if (
        !overrideImageId ||
        (overrideImage && overrideImage.id === overrideImageId) ||
        imagesRaw.loadingOverrideImage
      ) {
        return false;
      }
      return { actionCreator: 'doFetchOverrideImage' };
    },
  ),

  selectOverrideImageId: createSelector(
    'selectQueryObject',
    queryObject => queryObject.overrideImageId,
  ),

  selectOverrideImage: createSelector(
    'selectOverrideImageId',
    'selectImagesRaw',
    (overrideImageId, imagesRaw) => overrideImageId && imagesRaw.overrideImage,
  ),

  selectImagesRaw: state => state.images,
  selectImages: state => state.images.items || [],
  selectImagesLoading: state => state.images.loading,

  selectImageSortBy: state => state.images.sortBy,
  selectImageSortDirection: state => state.images.sortDirection,

  selectImagesFilterValues: state => state.images.filterValues,
};
