import { connect } from 'redux-bundler-react';
import { withStyles } from '@material-ui/core';
import React, { Component } from 'react';

import UserMenu from './';
import styles from './styles';

class UserMenuContainer extends Component {
  render() {
    const { currentUser } = this.props;
    return <UserMenu user={currentUser} />;
  }
}

export default connect(
  'selectCurrentUser',
  withStyles(styles, { withTheme: true })(UserMenuContainer),
);
