import { createSelector } from 'redux-bundler';

import AwsAccount from '../models/aws_account';
import Client from '../models/client';
import ClientUserAssignment from '../models/client_user_assignment';

const STALE_AFTER = 120000;
export default {
  name: 'clients',
  getReducer: () => {
    const initialData = {
      data: null,
      sortDirection: 'ASC',
      sortBy: 'name',
      loading: false,
      activeClient: null,
      loadingActiveClient: false,
      activeClientLoadedAt: null,
      clientsLoadedAt: null,
    };

    return (state = initialData, { type, payload }) => {
      if (type === 'FETCH_CLIENT_START') {
        return { ...state, loadingActiveClient: true };
      }
      if (type === 'FETCH_CLIENT_SUCCESS') {
        return {
          ...state,
          loadingActiveClient: false,
          activeClient: payload.result,
          activeClientLoadedAt: Date.now(),
        };
      }
      if (type === 'FETCH_CLIENTS_START') {
        return { ...state, loading: true };
      }
      if (type === 'FETCH_CLIENTS_SUCCESS') {
        return {
          ...state,
          loading: false,
          data: payload.results,
          clientsLoadedAt: Date.now(),
        };
      }
      if (type === 'CHANGE_CLIENT_SORT') {
        return {
          ...state,
          sortDirection: payload.sortDirection,
          sortBy: payload.sortBy,
        };
      }
      if (type === 'ADD_CLIENT') {
        return { ...state, data: state.data.concat([payload.client]) };
      }
      if (type === 'UPDATE_CLIENT') {
        const { client: nextClient } = payload;
        return {
          ...state,
          data: state.data.map(client =>
            client.id === nextClient.id ? nextClient : client,
          ),
          activeClient:
            state.activeClient.id === nextClient.id
              ? nextClient
              : state.activeClient,
        };
      }
      if (type === 'TOGGLE_CLIENT_SELECTION') {
        const { client } = payload;
        const nextClient = client.dup();

        nextClient.selected = !nextClient.selected;

        return {
          ...state,
          data: state.data.map(c => (c.id === nextClient.id ? nextClient : c)),
        };
      }
      if (type === 'REMOVE_CLIENT') {
        const index = state.data.findIndex(u => u.id === payload.client.id);
        return {
          ...state,
          data: state.data.slice(0, index).concat(state.data.slice(index + 1)),
        };
      }
      if (type === 'RESET_CLIENT') {
        return {
          ...state,
          activeClient: null,
        };
      }

      return state;
    };
  },

  doResetClient: () => ({ dispatch }) => {
    dispatch({ type: 'RESET_CLIENT' });
  },

  doFetchClients: () => async ({ dispatch, getState }) => {
    const state = getState();
    const { sortBy } = state.clients;
    const { sortDirection } = state.clients;

    dispatch({ type: 'FETCH_CLIENTS_START' });
    const response = await Client.order({
      [sortBy]: sortDirection.toLowerCase(),
    }).all();
    dispatch({
      type: 'FETCH_CLIENTS_SUCCESS',
      payload: { results: response.data },
    });
  },

  doFetchClient: clientId => async ({ dispatch }) => {
    dispatch({ type: 'FETCH_CLIENT_START' });

    const response = await Client.includes([
      { accounts: 'regions' },
      { users: 'roles' },
      { facilities: ['region', 'account', 'storage_gateway'] },
    ]).find(clientId);
    dispatch({
      type: 'FETCH_CLIENT_SUCCESS',
      payload: { result: response.data },
    });
  },

  doChangeClientSort: ({ sortDirection, sortBy }) => async ({
    dispatch,
    store,
  }) => {
    dispatch({
      type: 'CHANGE_CLIENT_SORT',
      payload: { sortDirection, sortBy },
    });
    store.doFetchClients();
  },

  doToggleClientSelection: client => ({ dispatch }) => {
    dispatch({ type: 'TOGGLE_CLIENT_SELECTION', payload: { client } });
  },

  doCreateClient: values => async ({ dispatch }) => {
    const client = new Client({
      name: values.name,
      workosProvider: values.workosProvider,
      workosConnectionId: values.workosConnectionId,
      domain: values.domain,
    });

    await client.save({});

    dispatch({ type: 'ADD_CLIENT', payload: { client } });

    return client;
  },

  doUpdateClient: values => async ({ dispatch, getState, store }) => {
    const state = getState();
    const client = store.selectActiveClient(state).dup();

    client.assignAttributes(values);

    const success = await client.save({});

    if (success) {
      dispatch({ type: 'UPDATE_CLIENT', payload: { client } });
    }
    return client;
  },

  doCreateAccount: values => async ({ store, getState }) => {
    const state = getState();
    const client = store.selectActiveClient(state).dup();

    const lastAccount = client.accounts[client.accounts.length - 1];

    if (lastAccount && !lastAccount.isPersisted) {
      client.accounts.pop();
    }

    const account = new AwsAccount({
      name: values.name,
      email: values.email,
      roleArn: values.roleArn,
      accountId: values.accountId,
      kmsKeyId: values.kmsKeyId,
      enableLeastPrivilegeIam: values.enableLeastPrivilegeIam,
    });
    client.accounts.push(account);
    await client.save({ with: ['accounts'] });

    return account;
  },

  doDestroyClient: client => async ({ dispatch }) => {
    await client.destroy();
    dispatch({ type: 'REMOVE_CLIENT', payload: { client } });
  },

  doAddUsersToClient: users => async ({ getState, store }) => {
    const state = getState();
    const activeClient = store.selectActiveClient(state);

    const nextClient = activeClient.dup();

    const clientUserAssignments = users.map(
      user => new ClientUserAssignment({ user }),
    );

    nextClient.clientUserAssignments = [
      ...nextClient.clientUserAssignments,
      ...clientUserAssignments,
    ];

    await nextClient.save({
      with: [{ clientUserAssignments: 'user.id' }],
    });

    store.doFetchUsers();
  },

  doRemoveUserFromClient: user => async ({ getState, store }) => {
    const state = getState();
    const activeClient = store.selectActiveClient(state);

    const cua = user.clientUserAssignmentForClient(activeClient);
    await cua.destroy();
    store.doFetchUsers();
  },

  selectClientSortBy: state => state.clients.sortBy,
  selectClientSortDirection: state => state.clients.sortDirection,
  selectClientLoading: state => state.clients.loading,
  selectClientRaw: state => state.clients,
  selectClientDataArray: state => state.clients.data || [],
  selectClientData: createSelector(
    'selectClientDataArray',
    'selectPrivacyMode',
    (clientDataArray, privacyMode) =>
      privacyMode
        ? clientDataArray.filter(x => x.privacyMode)
        : clientDataArray,
  ),
  selectSelectedClientCount: createSelector(
    'selectClientData',
    clientData => clientData.filter(u => u.selected).length,
  ),
  selectSelectedClients: createSelector('selectClientData', clientData =>
    clientData.filter(u => u.selected),
  ),
  selectActiveClient: createSelector(
    'selectActiveClientId',
    'selectClientRaw',
    (clientId, clientData) => clientId && clientData.activeClient,
  ),
  selectActiveClientId: createSelector(
    'selectRouteParams',
    routeParams => routeParams.id,
  ),

  selectActiveClientIsStale: createSelector(
    'selectClientRaw',
    'selectAppTime',
    (clientData, appTime) => {
      if (!clientData.activeClientLoadedAt) {
        return true;
      }
      return appTime - clientData.activeClientLoadedAt > STALE_AFTER;
    },
  ),

  selectClientsAreStale: createSelector(
    'selectClientRaw',
    'selectAppTime',
    (clientData, appTime) => {
      if (!clientData.clientsLoadedAt) {
        return true;
      }
      return appTime - clientData.clientsLoadedAt > STALE_AFTER;
    },
  ),
  reactShouldFetchClients: createSelector(
    'selectClientRaw',
    'selectCurrentUser',
    'selectClientsAreStale',
    (clientData, currentUser, clientsAreStale) => {
      if (clientData.loading) {
        return false;
      }
      if (clientData.data && !clientsAreStale) {
        return false;
      }
      if (!currentUser) {
        return false;
      }
      return { actionCreator: 'doFetchClients' };
    },
  ),

  reactShouldResetClient: createSelector(
    'selectActiveClientId',
    'selectClientRaw',
    (activeClientId, clientData) => {
      const { activeClient } = clientData;
      if (!activeClient) return null;
      if (activeClient.id !== activeClientId) {
        return { actionCreator: 'doResetClient' };
      }

      return null;
    },
  ),

  reactShouldFetchActiveClient: createSelector(
    'selectRouteParams',
    'selectAuthenticatedUser',
    'selectPathname',
    'selectActiveClient',
    'selectClientRaw',
    'selectActiveClientIsStale',
    (
      routeParams,
      authenticatedUser,
      pathname,
      activeClient,
      clientData,
      activeClientIsStale,
    ) => {
      if (
        !pathname.includes('/client') ||
        !routeParams.id ||
        clientData.loadingActiveClient
      ) {
        return null;
      }
      if (
        activeClient &&
        activeClient.id === routeParams.id &&
        !activeClientIsStale
      ) {
        return null;
      }
      if (!authenticatedUser) {
        return null;
      }

      return { actionCreator: 'doFetchClient', args: [routeParams.id] };
    },
  ),
};
