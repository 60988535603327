import { createSelector } from 'redux-bundler';

export default {
  name: 'utils',
  selectRouteApis: createSelector('selectRoute', route => {
    const { options = {} } = route;
    return options.apis || [];
  }),
  selectBranchNameParam: createSelector('selectRouteParams', routeParams => {
    console.log(routeParams);
    return routeParams.branchName;
  }),
  selectBaseUrl: createSelector(
    'selectActiveClient',
    'selectActiveFacility',
    (activeClient, activeFacility) => {
      if (activeClient && activeFacility) {
        return `/clients/${activeClient.id}/facilities/${activeFacility.id}`;
      }

      if (activeClient) {
        return `/clients/${activeClient.id}`;
      }

      return null;
    },
  ),
};
