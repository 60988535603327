import { createSelector } from 'redux-bundler';
import { snakeCase } from 'lodash';

import Client from '../models/client';
import ClientUserAssignment from '../models/client_user_assignment';
import RoleUserAssignment from '../models/role_user_assignment';
import TeamMembership from '../models/team_membership';
import User from '../models/user';
import UserActivation from '../models/user_activation';
import Permission from '../models/permission';
import UserSuspension from '../models/user_suspension';

export default {
  name: 'users',
  getReducer: () => {
    const initialData = {
      data: null,
      sortDirection: 'ASC',
      sortBy: 'name',
      loading: false,
      activeUser: null,
      loadingActiveUser: null,
      loadingAuthenticatedUser: false,
      authenticatedUser: null,
      errors: 0,
    };

    return (state = initialData, { type, payload }) => {
      if (type === 'FETCH_AUTHENTICATED_USER_START') {
        return { ...state, loadingAuthenticatedUser: true };
      }
      if (type === 'FETCH_AUTHENTICATED_USER_SUCCESS') {
        return {
          ...state,
          loadingAuthenticatedUser: false,
          authenticatedUser: payload.results,
        };
      }
      if (type === 'FETCH_AUTHENTICATED_USER_ERROR') {
        return {
          ...state,
          loadingAuthenticatedUser: false,
          errors: state.errors + 1,
          err: payload.err,
        };
      }
      if (type === 'FETCH_USER_START') {
        return { ...state, loadingActiveUser: true };
      }
      if (type === 'FETCH_USER_SUCCESS') {
        return {
          ...state,
          loadingActiveUser: false,
          activeUser: payload.result,
        };
      }
      if (type === 'FETCH_USERS_START') {
        return { ...state, loading: true };
      }
      if (type === 'FETCH_USERS_SUCCESS') {
        return { ...state, loading: false, data: payload.results };
      }

      if (type === 'FETCH_SEARCH_USERS_SUCCESS') {
        return { ...state, loading: false, searchUsers: payload.results };
      }
      if (type === 'CHANGE_USER_SORT') {
        return {
          ...state,
          sortDirection: payload.sortDirection,
          sortBy: payload.sortBy,
        };
      }
      if (type === 'ADD_USER') {
        return { ...state, data: state.data.concat([payload.user]) };
      }
      if (type === 'UPDATE_USER') {
        if (state.data) {
          const index = state.data.findIndex(u => u.id === payload.user.id);
          const { user } = payload;
          return {
            ...state,
            data: state.data
              .slice(0, index)
              .concat([user])
              .concat(state.data.slice(index + 1)),
            activeUser:
              state.activeUser && state.activeUser.id === payload.user.id
                ? user
                : state.activeUser,
          };
        }

        return {
          ...state,
          activeUser: payload.user,
        };
      }
      if (type === 'TOGGLE_USER_SELECTION') {
        const index = state.data.findIndex(u => u.id === payload.user.id);
        const { user } = payload;
        user.selected = !user.selected;
        user.reset();
        return {
          ...state,
          data: state.data
            .slice(0, index)
            .concat([user])
            .concat(state.data.slice(index + 1)),
        };
      }
      if (type === 'REMOVE_USER') {
        const index = state.data.findIndex(u => u.id === payload.user.id);
        return {
          ...state,
          data: state.data.slice(0, index).concat(state.data.slice(index + 1)),
        };
      }

      if (type === 'RESET_CLIENT') {
        return {
          ...state,
          data: null,
          activeUser: null,
        };
      }

      if (type === 'RESET_USER') {
        return {
          ...state,
          activeUser: null,
        };
      }
      return state;
    };
  },

  doResetUser: () => ({ dispatch }) => {
    dispatch({ type: 'RESET_USER' });
  },

  doFetchAuthenticatedUser: () => async ({ dispatch, store }) => {
    dispatch({ type: 'FETCH_AUTHENTICATED_USER_START' });

    try {
      const response = await User.where({
        authenticated: true,
      })
        .includes(['permissions', 'client_user_assignments'])
        .first();
      dispatch({
        type: 'FETCH_AUTHENTICATED_USER_SUCCESS',
        payload: { results: response.data },
      });
      store.doSetCurrentUser(response.data);
    } catch (err) {
      dispatch({
        type: 'FETCH_AUTHENTICATED_USER_ERROR',
        payload: { err: err },
      });
    }
  },

  doFetchUsers: () => async ({ dispatch, getState, store }) => {
    const state = getState();
    const { sortBy } = state.users;
    const { sortDirection } = state.users;

    const client = store.selectActiveClient(state);

    dispatch({ type: 'FETCH_USERS_START' });
    const response = await User.order({
      [snakeCase(sortBy)]: sortDirection.toLowerCase(),
    })
      .where({
        client_id: client.id,
        client_user_assignments: { client_id: client.id },
        user_hours_stat: { client_id: client.id },
      })
      .includes([
        'roles',
        { client_user_assignments: { invitation: 'team' } },
        'last_workstation_login',
        'user_hours_stat',
      ])
      .all();
    dispatch({
      type: 'FETCH_USERS_SUCCESS',
      payload: { results: response.data },
    });
  },

  doFetchActiveUser: userId => async ({ dispatch, store, getState }) => {
    const state = getState();

    const client = store.selectActiveClient(state);
    dispatch({ type: 'FETCH_USER_START' });

    const response = await User.includes([
      'role_user_assignments',
      'roles',
      { teams: { groups: 'facility' } },
      { client_user_assignments: { invitation: 'team' } },
      'last_workstation_login',
      { team_memberships: 'team' },
      'user_hours_stat',
      'workstation_hours_stat',
    ])
      .where({
        client_user_assignments: { client_id: client.id },
        teams: { client_id: client.id },
        last_workstation_login: { client_id: client.id },
        user_hours_stat: { client_id: client.id },
        workstation_hours_stat: { client_id: client.id },
        // last_workstation_login: { client_id: client.id },
      })
      .find(userId);
    dispatch({
      type: 'FETCH_USER_SUCCESS',
      payload: { result: response.data },
    });
  },

  doSetActiveUser: user => ({ dispatch }) => {
    dispatch({ type: 'FETCH_USER_SUCCESS', payload: { result: user } });
  },

  doChangeUserSort: ({ sortDirection, sortBy }) => async ({
    dispatch,
    store,
  }) => {
    dispatch({ type: 'CHANGE_USER_SORT', payload: { sortDirection, sortBy } });
    store.doFetchUsers();
  },

  doToggleUserSelection: user => ({ dispatch }) => {
    dispatch({ type: 'TOGGLE_USER_SELECTION', payload: { user } });
  },

  doCreateUser: values => async ({ dispatch, store, getState }) => {
    const state = getState();
    const client = store.selectActiveClient(state);
    const user = new User({
      firstName: values.user.firstName,
      lastName: values.user.lastName,
      email: values.user.email,
      teams: [values.user.team],
      roles: [values.user.role],
    });

    const rua = new RoleUserAssignment({
      role: values.user.role,
    });

    const cua = new ClientUserAssignment({
      client,
      username: values.cua.username,
      password: values.cua.password,
    });

    const teamMembership = new TeamMembership({
      team: values.user.team,
    });

    user.clientUserAssignments.push(cua);

    user.roleUserAssignments.push(rua);
    user.teamMemberships.push(teamMembership);
    const success = await user.save({
      with: [
        { roleUserAssignments: 'role.id' },
        { clientUserAssignments: 'client.id' },
        { teamMemberships: 'team.id' },
      ],
    });
    if (success) {
      dispatch({ type: 'ADD_USER', payload: { user } });
    }
    return user;
  },

  doDestroyUser: user => async ({ dispatch }) => {
    await user.destroy();
    dispatch({ type: 'REMOVE_USER', payload: { user } });
  },

  doUpdateUser: values => async ({ dispatch, store, getState }) => {
    const state = getState();
    const client = store.selectActiveClient(state);

    const {
      users: { activeUser },
    } = store.getState();

    const user = activeUser.dup();

    const cua = user.clientUserAssignmentForClient(client);
    if (cua) {
      cua.assignAttributes(values.cua);
      user.assignAttributes(values.user);
    }
    if (values.user.role && values.user.role.id !== activeUser.roles[0].id) {
      console.log(values.user.role.id);
      console.log(activeUser.roles[0].id);
      const newRua = new RoleUserAssignment({
        roleId: values.user.role.id,
      });

      user.roleUserAssignments = [
        ...user.roleUserAssignments.map(rua => {
          const nextRua = rua.dup();
          nextRua.isMarkedForDestruction = true;
          return nextRua;
        }),
        newRua,
      ];

      const success = await user.save({
        with: ['clientUserAssignments', { roleUserAssignments: 'role.id' }],
      });

      if (success) {
        await store.doFetchActiveUser(user.id);
      }
    } else {
      const success = await user.save({
        with: ['clientUserAssignments'],
      });

      if (success) {
        await store.doFetchActiveUser(user.id);
      }
    }

    return user;
  },

  doAddUserToTeam: (user, team) => async ({ dispatch, store, getState }) => {
    const state = getState();
    const client = store.selectActiveClient(state);
    const nextUser = user.dup();

    const teamMembership = new TeamMembership({});
    teamMembership.team = team;

    nextUser.assignAttributes({
      teamMemberships: [...nextUser.teamMemberships, teamMembership],
    });

    const success = await nextUser.save({
      with: [{ teamMemberships: 'team.id' }, 'teams'],
    });

    const response = await User.includes([
      'role_user_assignments',
      'roles',
      { teams: { groups: 'facility' } },
      'client_user_assignments',
      'last_workstation_login',
      { team_memberships: 'team' },
      'user_hours_stat',
      'workstation_hours_stat',
    ])
      .where({
        teams: { client_id: client.id },
      })
      .find(nextUser.id);
    const reloadedUser = response.data;

    if (success) {
      dispatch({
        type: 'UPDATE_USER',
        payload: { user: reloadedUser },
      });
    }
    return reloadedUser;
  },

  doRemoveUserFromTeam: (user, team) => async ({
    dispatch,
    store,
    getState,
  }) => {
    const state = getState();
    const client = store.selectActiveClient(state);

    let response = await User.includes([
      'roles',
      'teams',
      { team_memberships: 'team' },
    ]).find(user.id);
    const nextUser = response.data;

    nextUser.teamMemberships.forEach(tm => {
      if (tm.team.id === team.id) {
        tm.isMarkedForDestruction = true;
      }
    });

    const success = await nextUser.save({
      with: [{ teamMemberships: 'team.id' }],
    });

    if (!success) {
      return;
    }

    response = await User.includes([
      'role_user_assignments',
      'roles',
      { teams: { groups: 'facility' } },
      'client_user_assignments',
      'last_workstation_login',
      { team_memberships: 'team' },
      'user_hours_stat',
      'workstation_hours_stat',
    ])
      .where({
        teams: { client_id: client.id },
      })
      .find(nextUser.id);
    const reloadedUser = response.data;

    if (success) {
      dispatch({
        type: 'UPDATE_USER',
        payload: { user: reloadedUser },
      });

      const state = getState();
      const activeTeam = store.selectActiveTeam(state);
      if (activeTeam) {
        store.doFetchActiveTeam(activeTeam.id);
      }
    }
    return reloadedUser;
  },

  doSuspendUser: user => async ({ dispatch }) => {
    const userSuspension = new UserSuspension({ user });
    await userSuspension.save({ with: 'user.id' });
    dispatch({ type: 'UPDATE_USER', payload: { user: userSuspension.user } });
  },

  doActivateUser: user => async ({ dispatch }) => {
    const userActivation = new UserActivation({ user });
    await userActivation.save({ with: 'user.id' });
    dispatch({ type: 'UPDATE_USER', payload: { user: userActivation.user } });
  },

  selectUserSortBy: state => state.users.sortBy,
  selectUserSortDirection: state => state.users.sortDirection,
  selectUserLoading: state => state.users.loading,
  selectUserRaw: state => state.users,
  selectUserData: state => state.users.data || [],
  selectSelectedUserCount: createSelector('selectUserData', userData => {
    return userData.filter(u => u.selected).length;
  }),
  selectSelectedUsers: createSelector('selectUserData', userData => {
    return userData.filter(u => u.selected);
  }),
  selectActiveUserId: createSelector(
    'selectRouteParams',
    routeParams => routeParams.userId,
  ),
  selectActiveUser: createSelector(
    'selectUserRaw',
    userData => userData.activeUser,
  ),
  selectAuthenticatedUser: createSelector(
    'selectUserRaw',
    userData => userData.authenticatedUser,
  ),
  reactShouldFetchUser: createSelector(
    'selectUserRaw',
    'selectAuthenticatedUser',
    'selectActiveClient',
    (userData, authenticatedUser, activeClient) => {
      if (
        userData.loading ||
        userData.data ||
        !authenticatedUser ||
        !activeClient
      ) {
        return false;
      }
      if (!authenticatedUser.isAuthorized('ui.users.view')) {
        return false;
      }
      return { actionCreator: 'doFetchUsers' };
    },
  ),
  reactShouldFetchActiveUser: createSelector(
    'selectRouteParams',
    'selectPathname',
    'selectActiveUser',
    'selectUserRaw',
    'selectActiveClient',
    (routeParams, pathname, activeUser, userData, activeClient) => {
      if (
        !pathname.includes('/user') ||
        !routeParams.userId ||
        userData.loadingActiveUser
      ) {
        return null;
      }
      if (activeUser && activeUser.id === routeParams.userId) {
        return null;
      }
      if (!activeClient) {
        return null;
      }

      return { actionCreator: 'doFetchActiveUser', args: [routeParams.userId] };
    },
  ),

  reactShouldFetchAuthenticatedUser: createSelector(
    'selectCurrentUser',
    'selectAuthenticatedUser',
    'selectUserRaw',
    (currentUser, authenticatedUser, userData) => {
      if (currentUser === null) {
        return null;
      }

      if (authenticatedUser || userData.loadingAuthenticatedUser) {
        return null;
      }

      if (userData.errors > 10) {
        return null;
      }

      return { actionCreator: 'doFetchAuthenticatedUser' };
    },
  ),

  reactShouldResetUser: createSelector(
    'selectActiveUserId',
    'selectActiveUser',
    (activeUserId, activeUser) => {
      if (!activeUser) return null;
      if (!activeUserId) return null;
      if (activeUser.id !== activeUserId) {
        return { actionCreator: 'doResetUser' };
      }

      return null;
    },
  ),
};
