import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { connect } from 'redux-bundler-react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import React from 'react';
import * as yup from 'yup';

import ConfirmationDialog from './ConfirmationDialog';

const styles = theme => ({
  inProgress: {
    display: 'flex',
    alignItems: 'center',
  },

  spinner: {
    marginLeft: theme.spacing(1),
  },
  requirement: {
    marginTop: theme.spacing(1),
    display: 'flex',
    color: theme.palette.text.secondary,

    alignItems: 'center',
  },
  requirementIcon: {
    marginRight: theme.spacing(1),
  },
});

const PasswordTip = withStyles(styles)(({ classes, children, ticked }) => (
  <div className={classes.requirement}>
    {ticked ? (
      <CheckCircleIcon className={classes.requirementIcon} fontSize="small" />
    ) : (
      <RadioButtonUncheckedIcon
        className={classes.requirementIcon}
        fontSize="small"
      />
    )}
    {children}
  </div>
));

const PasswordChangeDialog = ({
  classes,
  open,
  onClose,
  doChangePassword,
  activeClient,
}) => {
  const [password, setPassword] = React.useState('');
  const [passwordConfirmation, setPasswordConfirmation] = React.useState('');
  const [passwordMatches, setPasswordMatches] = React.useState(false);
  const [busy, setBusy] = React.useState(false);
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [message, setMessage] = React.useState(false);

  React.useEffect(() => {
    setPasswordMatches(password && password === passwordConfirmation);
  }, [password, passwordConfirmation]);

  const handleConfirm = async (values, { setErrors, setSubmitting }) => {
    try {
      setBusy(true);
      const passwordChange = await doChangePassword(values.password);
      if (passwordChange.isPersisted) {
        setBusy(false);
        setShowConfirmation(true);
        setMessage('Your password has been succesfully changed');
        onClose();
        return;
      }
      const errors = mapErrorsToFormik(passwordChange.errors);
      console.log(errors);

      setErrors(errors);
      setSubmitting(false);
      setBusy(false);
    } catch (err) {
      setShowConfirmation(true);
      setMessage(
        'An error occurred updating your password. Please contact support',
      );

      setSubmitting(false);
      setBusy(false);
    }
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const mapErrorsToFormik = errors =>
    Object.keys(errors).reduce(
      (memo, key) => ({ ...memo, [key]: `${key} ${errors[key].message}` }),
      {},
    );

  React.useEffect(() => {
    const timer = setTimeout(() => {
      open && inputEl.current.focus();
    }, 100);
    return () => clearTimeout(timer);
  }, [open]);

  const inputEl = React.useRef(null);

  return (
    <>
      <ConfirmationDialog
        title="Change Password"
        open={showConfirmation}
        onClose={handleCloseConfirmation}
        message={message}
      />
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        fullWidth
        aria-labelledby="confirmation-dialog-title"
        open={open}
      >
        <Formik
          enableReinitialize
          initialValues={{
            password: '',
            passwordConfirmation: '',
          }}
          validationSchema={yup.object().shape({
            password: yup
              .string()
              .required('Password is a required field')
              .min(8),
            passwordConfirmation: yup
              .string()
              .required()
              .oneOf([yup.ref('password'), null], 'Passwords must match'),
          })}
          onSubmit={handleConfirm}
          validateOnMount
        >
          {({ isValid, values }) => {
            return (
              <Form>
                <DialogTitle>Change Password</DialogTitle>
                <DialogContent>
                  Change your password for all{' '}
                  <strong>{activeClient.name}</strong> workstations
                  <div>
                    <Field
                      name="password"
                      type="password"
                      fullWidth
                      component={TextField}
                      margin="normal"
                      variant="outlined"
                      label="Password"
                      autofocus
                      inputRef={inputEl}
                    />
                    Please use at least:
                    <Grid container>
                      <Grid xs={6}>
                        <PasswordTip ticked={values.password.length >= 8}>
                          8 or more characters
                        </PasswordTip>
                        <PasswordTip ticked={values.password.match(/[A-Z]/)}>
                          1 uppercase character
                        </PasswordTip>
                        <PasswordTip ticked={values.password.match(/[a-z]/)}>
                          1 lowercase character
                        </PasswordTip>
                      </Grid>
                      <Grid xs={6}>
                        <PasswordTip
                          ticked={values.password.match(
                            /[!"\#$%&'()*+, \-./:;<=>?@\[\\\]^_‘{|}~]/,
                          )}
                        >
                          1 punctuation character
                        </PasswordTip>
                        <PasswordTip ticked={values.password.match(/[0-9]/)}>
                          1 number
                        </PasswordTip>

                        <PasswordTip
                          ticked={values.password.match(
                            /(?![A-Z])(?![a-z])[\p{L}\p{Nl}]/u,
                          )}
                        >
                          1 special character
                        </PasswordTip>
                      </Grid>
                    </Grid>
                    <Field
                      name="passwordConfirmation"
                      type="password"
                      fullWidth
                      component={TextField}
                      margin="normal"
                      variant="outlined"
                      label="Confirm Password"
                    />
                  </div>
                  {busy && (
                    <Typography variant="body" className={classes.inProgress}>
                      Updating password...{' '}
                      <CircularProgress size={20} className={classes.spinner} />
                    </Typography>
                  )}
                </DialogContent>

                <DialogActions>
                  <Button onClick={onClose} color="primary">
                    Cancel
                  </Button>
                  <Button
                    color="secondary"
                    type="submit"
                    disabled={!isValid || busy}
                  >
                    Change Password
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </>
  );
};

export default connect(
  'doChangePassword',
  'selectActiveClient',
  withStyles(styles)(PasswordChangeDialog),
);
