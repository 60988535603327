import { darken } from '@material-ui/core/styles';

export default theme => ({
  appBarColor: {

    backgroundColor:
      theme.palette.type === 'dark'
        ? theme.palette.background.paper
        : '#464748',
  },

  logoWrapper: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  },

  logo: {
    height: '44px',
    marginLeft: -2,
    paddingRight: 20,

    [theme.breakpoints.down('xs')]: {
      height: '40px',
    },
  },
  appBarShift: {
    zIndex: 1300,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  toolbar: {
    paddingLeft: 18,
  },
});
