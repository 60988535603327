import { Avatar, withStyles } from '@material-ui/core';
import { connect } from 'redux-bundler-react';
import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

import Facility from '../../../models/facility';

const style = theme => ({
  facility: {
    width: theme.spacing(6),
    height: theme.spacing(6),

    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    color: 'white',
  },
});

const FacilityAvatar = ({ classes, activeFacility, className }) => (
  <Avatar
    variant="rounded"
    className={cx(classes.facility, className)}
    style={{ backgroundColor: activeFacility.avatarColor(), color: 'white' }}
  >
    {activeFacility.name[0]}
  </Avatar>
);

FacilityAvatar.propTypes = {
  activeFacility: PropTypes.instanceOf(Facility).isRequired,
};
export default connect(
  'selectActiveFacility',
  withStyles(style, { withTheme: true })(FacilityAvatar),
);
