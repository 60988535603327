import { Attr, HasMany, HasOne, Model } from 'spraypaint';
import moment from 'moment';

import { ApplicationRecord } from './application_record';
import ChefCookbook from './chef_cookbook';
import ChefPackageSync from './chef_package_sync';

@Model()
export default class ChefPackage extends ApplicationRecord {
  static jsonapiType = 'chef_packages';
  @Attr() name: string;
  @Attr() url: string;
  @Attr() createdAt: string;
  @Attr() updatedAt: string;
  @Attr({ persist: false }) selected: boolean;

  @HasOne() pendingChefPackageSync: ChefPackageSync;
  @HasOne() completedChefPackageSync: ChefPackageSync;
  @HasOne() lastChefPackageSync: ChefPackageSync;
  @HasMany() cookbooks: ChefCookbook[];

  isSyncPending() {
    return this.lastChefPackageSync && this.lastChefPackageSync.isPending();
  }

  isSyncStarted() {
    return this.lastChefPackageSync && this.lastChefPackageSync.isStarted();
  }

  isSyncPendingOrStarted() {
    return (
      (this.lastChefPackageSync && this.lastChefPackageSync.isPending()) ||
      (this.lastChefPackageSync && this.lastChefPackageSync.isStarted())
    );
  }

  isSyncRecentlyCompleted() {
    const completed =
      this.lastChefPackageSync && this.lastChefPackageSync.isComplete();

    if (completed) {
      const now = moment(new Date());

      const duration = moment
        .duration(now.diff(moment.utc(this.lastChefPackageSync.createdAt)))
        .asMinutes();

      return duration < 60;
    }
    return false;
  }

  lastSyncTime() {
    return moment
      .utc(this.lastChefPackageSync.createdAt)
      .local()
      .calendar();
  }

  isSyncFailed() {
    return this.lastChefPackageSync && this.lastChefPackageSync.isFailed();
  }

  constructor(attrs?: Record<string, any>) {
    super(attrs);
    this.selected = false;
  }
}
