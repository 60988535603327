import { Tooltip, withStyles } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

const styles = theme => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
});

function InfoTooltip({ children, classes, margin = true }) {
  return (
    <Tooltip title={children} interactive>
      <InfoIcon
        className={cx(margin ? classes.icon : null, classes.icon)}
        color="disabled"
        fontSize="small"
      />
    </Tooltip>
  );
}

InfoTooltip.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withStyles(styles)(InfoTooltip);
