import { Alert } from '@material-ui/lab';
import {
  Button,
  TextField,
  withStyles,
  CircularProgress,
} from '@material-ui/core';
import { connect } from 'redux-bundler-react';
import PropTypes from 'prop-types';
import React from 'react';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';

import ArchPaper from '../../molecules/ArchPaper';
import PasswordChangeForm from '../password_change_dialog/PasswordChangeForm';

const getUserAgent = () => {
  return window.navigator.userAgent;
};

const userAgentContains = browserName => {
  browserName = browserName.toLowerCase();
  return (
    getUserAgent()
      .toLowerCase()
      .indexOf(browserName) > -1
  );
};

const isOSX = () => {
  return userAgentContains('Macintosh');
};

const isWindows = () => {
  return userAgentContains('Windows');
};

const isLinux = () => {
  return userAgentContains('linux');
};

const teradiciClientDownloadInfo = () => {
  if (isLinux()) {
    return {
      version: 'Download for Linux',
      url: 'https://docs.teradici.com/find/product/software-and-mobile-clients',
      download: false,
    };
  }

  if (isWindows()) {
    return {
      version: 'Download for Windows',
      url:
        'https://dl.teradici.com/DeAdBCiUYInHcSTy/pcoip-client/raw/names/pcoip-client-exe/versions/latest/pcoip-client_latest.exe',

      download: true,
    };
  }

  if (isOSX()) {
    return {
      version: 'Download for Mac',
      url:
        'https://dl.teradici.com/DeAdBCiUYInHcSTy/pcoip-client/raw/names/pcoip-client-dmg/versions/latest/pcoip-client_latest.dmg',

      download: true,
    };
  }

  return {
    version: 'Download from Teradici.com',
    url: 'https://docs.teradici.com/find/product/software-and-mobile-clients',

    download: false,
  };
};

const styles = theme => ({
  root: {
    display: 'flex',
    height: '100vh',
    background: '#fafafa',
  },
  paper: {
    margin: 'auto',
    width: 550,
    padding: theme.spacing(2),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logo: {
    paddingTop: theme.spacing(2),
    width: 186,
    paddingBottom: theme.spacing(2),
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonWrapper: {
    width: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(3),
    width: '100%',
    marginTop: theme.spacing(3),
  },
  button: {
    position: 'relative',
    display: 'flex',
    padding: '0 0 0 48px',
    background: 'transparent',
    alignItems: 'center',
    width: '100%',
    height: '48px',
    fontSize: '14px',
    border: '1px solid #b3bac7',
    borderRadius: '3px',
    cursor: 'pointer',
    outline: '0',
    transition: 'background-color 0.25s ease-in-out',
    marginBottom: '8px',

    '&:hover': {
      background: 'rgba(47, 70, 100, .08)',
    },
  },
  buttonImage: {
    width: 16,
    left: 16,
    position: 'absolute',
    display: 'inline-block',
    top: '0px',
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  next: {
    // marginLeft: 'auto',
    // marginRight: 'auto',
    // display: 'block',
  },
  stepsContainer: {
    width: '100%',
    padding: theme.spacing(1),
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
  welcome: {
    textAlign: 'center',
  },
});
function getSteps() {
  return ['Welcome', 'Username/Password', 'Downloads'];
}

function InvitationScreen({
  classes,
  onSigninClick,
  activeInvitation,
  invitationsState,
  doCreateInvitationAcceptance,
  doSetPassword,
}) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [acceptanceFailed, setAcceptanceFailed] = React.useState(false);
  const [accepted, setAccepted] = React.useState(false);
  const [passwordUpdateFailed, setPasswordUpdateFailed] = React.useState(false);
  const [busy, setBusy] = React.useState(false);
  const steps = getSteps();
  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handlePasswordSubmit = async (values, { setSubmitting, setErrors }) => {
    setBusy(true);
    if (!accepted && (await doCreateInvitationAcceptance())) {
      setAccepted(true);
    } else {
      setAcceptanceFailed(true);
      setBusy(false);
      return;
    }

    if (await doSetPassword(values.password)) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
      setBusy(false);
      return;
    } else {
      setBusy(false);
      setPasswordUpdateFailed(true);
    }
  };

  const inputEl = React.useRef(null);
  console.log(invitationsState);

  if (invitationsState.error) {
    return (
      <div className={classes.root}>
        <ArchPaper elevation={1} className={classes.paper}>
          <Alert severity="warning">
            The link you clicked on couldn't be found.
          </Alert>
        </ArchPaper>
      </div>
    );
  }

  if (!activeInvitation) {
    return (
      <div className={classes.root}>
        <ArchPaper elevation={1} className={classes.paper}>
          <Alert icon={false}></Alert>
        </ArchPaper>
      </div>
    );
  }
  return (
    <div className={classes.root}>
      <ArchPaper elevation={1} className={classes.paper}>
        {activeStep < 3 && (
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            className={classes.stepsContainer}
          >
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        )}
        <div className={classes.stepsContainer}>
          {activeStep === 0 && (
            <div>
              <Alert icon={false}>
                Welcome! <strong>{activeInvitation.createdByName}</strong> has
                invited you to join {activeInvitation.clientName} at Arch.
                <br />
                <br />
                Next, we'll guide you to getting started with Arch and online in
                no time.
              </Alert>
              <br />
              <div className={classes.buttons}>
                <Button
                  className={classes.next}
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                >
                  Next
                </Button>
              </div>
            </div>
          )}

          {activeStep === 1 && (
            <div>
              <Alert icon={false} className={classes.alert}>
                To log into a workstation, you will need a workstation username
                and password
              </Alert>
              {acceptanceFailed && (
                <Alert severity="warning" className={classes.alert}>
                  Invitation could not be accepted
                </Alert>
              )}

              {passwordUpdateFailed && (
                <Alert severity="warning" className={classes.alert}>
                  Password could not be set
                </Alert>
              )}

              <TextField
                fullWidth
                variant="outlined"
                readonly
                label="Username"
                helperText="Your username was chosen by your administrator, and can't be changed"
                value={activeInvitation.username}
              />
              <PasswordChangeForm
                onSubmit={handlePasswordSubmit}
                buttonRenderer={isValid => {
                  return (
                    <div className={classes.buttons}>
                      <Button
                        disabled={!isValid || busy}
                        className={classes.next}
                        variant="contained"
                        color="primary"
                        type="submit"
                        startIcon={busy && <CircularProgress size={16} />}
                      >
                        Set Password
                      </Button>
                    </div>
                  );
                }}
              />
            </div>
          )}

          {activeStep === 2 && (
            <div>
              <Alert icon={false} className={classes.alert}>
                Download and install the Teradici PCoIP client to connect to
                your {activeInvitation.clientName} workstation
              </Alert>

              <div className={classes.buttons}>
                <Button
                  variant="outlined"
                  color="secondary"
                  className={classes.next}
                  component="a"
                  href={teradiciClientDownloadInfo().url}
                >
                  {teradiciClientDownloadInfo().version}
                </Button>
              </div>
              <br />

              <div className={classes.buttons}>
                <Button
                  className={classes.next}
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                >
                  Next
                </Button>
              </div>
            </div>
          )}

          {activeStep === 3 && (
            <div>
              <Alert icon={false} className={classes.alert}>
                You're all set up. Next, we will take you to your dashboard
              </Alert>
              <div className={classes.buttons}>
                <Button
                  className={classes.next}
                  variant="contained"
                  color="primary"
                  component="a"
                  href={`/clients/${activeInvitation.clientId}`}
                >
                  Done
                </Button>
              </div>
            </div>
          )}
        </div>
      </ArchPaper>
    </div>
  );
}

InvitationScreen.propTypes = {
  onSigninClick: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(
  connect(
    'selectActiveInvitation',
    'selectInvitationsState',
    'doCreateInvitationAcceptance',
    'doSetPassword',
    InvitationScreen,
  ),
);
