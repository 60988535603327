import { Button, withStyles } from '@material-ui/core';
import React, { Component } from 'react';

const styles = theme => ({
  button: {
    marginLeft: theme.spacing(1),
  },
});

class ArchTableToolbarActionButton extends Component {
  render() {
    const { children, classes, ...rest } = this.props;
    return (
      <Button
        disableElevation
        variant="contained"
        className={classes.button}
        {...rest}
      >
        {children}
      </Button>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  ArchTableToolbarActionButton,
);
