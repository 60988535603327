import { IconButton, withStyles } from '@material-ui/core';

export default withStyles(
  theme => ({
    root: {
      marginLeft: theme.spacing(1),
    },
  }),
  { withTheme: true },
)(IconButton);
