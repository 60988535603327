import { createSelector } from 'redux-bundler';
import { snakeCase } from 'lodash';

import ChefPackage from '../models/chef_package';
import ChefPackageSync from '../models/chef_package_sync';
import ChefCookbook from '../models/chef_cookbook';
import ChefRecipe from '../models/chef_recipe';

export default {
  name: 'chefPackages',
  getReducer: () => {
    const initialData = {
      data: null,
      sortDirection: 'ASC',
      sortBy: 'name',
      loading: false,
      all: false,
      activeChefPackage: null,
      loadingActiveChefPackage: null,
    };

    return (state = initialData, { type, payload }) => {
      if (type === 'FETCH_CHEF_PACKAGE_START') {
        return { ...state, loadingActiveChefPackage: true };
      }
      if (type === 'FETCH_CHEF_PACKAGE_SUCCESS') {
        return {
          ...state,
          loadingActiveChefPackage: false,
          activeChefPackage: payload.result,
        };
      }
      if (type === 'FETCH_CHEF_PACKAGES_START') {
        return { ...state, loading: true };
      }
      if (type === 'FETCH_CHEF_PACKAGES_SUCCESS') {
        return {
          ...state,
          loading: false,
          data: payload.results,
          all: payload.all,
        };
      }
      if (type === 'CHANGE_CHEF_PACKAGE_SORT') {
        return {
          ...state,
          sortDirection: payload.sortDirection,
          sortBy: payload.sortBy,
        };
      }
      if (type === 'ADD_CHEF_PACKAGE') {
        return { ...state, data: state.data.concat([payload.chefPackage]) };
      }
      if (type === 'UPDATE_CHEF_PACKAGE') {
        if (state.data) {
          const index = state.data.findIndex(
            u => u.id === payload.chefPackage.id,
          );
          const { chefPackage } = payload;
          return {
            ...state,
            data: state.data
              .slice(0, index)
              .concat([chefPackage])
              .concat(state.data.slice(index + 1)),
            activeChefPackage:
              state.activeChefPackage &&
              state.activeChefPackage.id === payload.chefPackage.id
                ? chefPackage
                : state.activeChefPackage,
          };
        }

        return {
          ...state,
          activeChefPackage: payload.chefPackage,
        };
      }
      if (type === 'TOGGLE_CHEF_PACKAGE_SELECTION') {
        const index = state.data.findIndex(
          u => u.id === payload.chefPackage.id,
        );
        const { chefPackage } = payload;
        chefPackage.selected = !chefPackage.selected;
        chefPackage.reset();
        return {
          ...state,
          data: state.data
            .slice(0, index)
            .concat([chefPackage])
            .concat(state.data.slice(index + 1)),
        };
      }
      if (type === 'REMOVE_CHEF_PACKAGE') {
        const index = state.data.findIndex(
          u => u.id === payload.chefPackage.id,
        );
        return {
          ...state,
          data: state.data.slice(0, index).concat(state.data.slice(index + 1)),
        };
      }

      if (type === 'RESET_CLIENT') {
        return {
          ...state,
          data: null,
        };
      }

      if (type === 'RESET_CHEF_PACKAGES') {
        return {
          ...state,
          data: null,
        };
      }

      if (type === 'RESET_CHEF_PACKAGE') {
        return {
          ...state,
          activeChefPackage: null,
        };
      }

      return state;
    };
  },

  doResetChefPackage: () => ({ dispatch }) => {
    dispatch({ type: 'RESET_CHEF_PACKAGE' });
  },

  doResetChefPackages: () => ({ dispatch }) => {
    dispatch({ type: 'RESET_CHEF_PACKAGES' });
  },

  doFetchChefPackages: () => async ({ dispatch, getState, store }) => {
    const state = getState();
    const activeClient = store.selectActiveClient(state);
    const { sortBy } = state.chefPackages;
    const { sortDirection } = state.chefPackages;

    dispatch({ type: 'FETCH_CHEF_PACKAGES_START' });
    const response = await ChefPackage.order({
      [snakeCase(sortBy)]: sortDirection.toLowerCase(),
    })
      .where({ client_id: activeClient.id })
      .all();
    dispatch({
      type: 'FETCH_CHEF_PACKAGES_SUCCESS',
      payload: { results: response.data, all: false },
    });
  },

  doFetchAllChefPackages: () => async ({ dispatch, getState, store }) => {
    const state = getState();
    const activeClient = store.selectActiveClient(state);
    const { sortBy } = state.chefPackages;
    const { sortDirection } = state.chefPackages;

    dispatch({ type: 'FETCH_CHEF_PACKAGES_START' });
    const response = await ChefPackage.order({
      [snakeCase(sortBy)]: sortDirection.toLowerCase(),
    }).all();
    dispatch({
      type: 'FETCH_CHEF_PACKAGES_SUCCESS',
      payload: { results: response.data, all: true },
    });
  },

  doFetchActiveChefPackage: chefPackageId => async ({ dispatch }) => {
    dispatch({ type: 'FETCH_CHEF_PACKAGE_START' });

    const response = await ChefPackage.includes([
      'last_chef_package_sync',
      { cookbooks: 'recipes' },
    ]).find(chefPackageId);
    dispatch({
      type: 'FETCH_CHEF_PACKAGE_SUCCESS',
      payload: { result: response.data },
    });
  },

  doRefreshChefPackage: () => async ({ store, getState }) => {
    const state = getState();
    const chefPackage = store.selectActiveChefPackage(state).dup();

    store.doFetchActiveChefPackage(chefPackage.id);
  },

  doChangeChefPackageSort: ({ sortDirection, sortBy }) => async ({
    dispatch,
    store,
  }) => {
    dispatch({
      type: 'CHANGE_CHEF_PACKAGE_SORT',
      payload: { sortDirection, sortBy },
    });
    store.doFetchChefPackages();
  },

  doToggleChefPackageSelection: chefPackage => ({ dispatch }) => {
    dispatch({
      type: 'TOGGLE_CHEF_PACKAGE_SELECTION',
      payload: { chefPackage },
    });
  },

  doCreateChefPackage: values => async ({ dispatch }) => {
    const chefPackage = new ChefPackage({
      name: values.name,
      url: values.url,
    });

    const success = await chefPackage.save({});
    if (success) {
      dispatch({ type: 'ADD_CHEF_PACKAGE', payload: { chefPackage } });
    }
    return chefPackage;
  },

  doDestroyChefPackage: chefPackage => async ({ dispatch }) => {
    await chefPackage.destroy();
    dispatch({ type: 'REMOVE_CHEF_PACKAGE', payload: { chefPackage } });
  },

  doUpdateChefPackage: values => async ({ dispatch, store }) => {
    const {
      chefPackages: { activeChefPackage },
    } = store.getState();

    const chefPackage = activeChefPackage.dup();
    chefPackage.assignAttributes(values);

    const success = await chefPackage.save();
    if (success) {
      dispatch({
        type: 'UPDATE_CHEF_PACKAGE',
        payload: { chefPackage },
      });
    }
    return chefPackage;
  },

  doSyncChefPackage: () => async ({ getState, store }) => {
    const state = getState();
    const chefPackage = store.selectActiveChefPackage(state).dup();

    const chefPackageSync = new ChefPackageSync({ chefPackage });

    await chefPackageSync.save({ with: ['chefPackage.id'] });
    if (chefPackageSync.isPersisted) {
      store.doFetchActiveChefPackage(chefPackage.id);
    }
  },

  selectChefPackageSortBy: state => state.chefPackages.sortBy,
  selectChefPackageSortDirection: state => state.chefPackages.sortDirection,
  selectChefPackageLoading: state => state.chefPackages.loading,
  selectChefPackageRaw: state => state.chefPackages,
  selectChefPackageData: state => state.chefPackages.data || [],
  selectSelectedChefPackageCount: createSelector(
    'selectChefPackageData',
    teamData => teamData.filter(u => u.selected).length,
  ),
  selectSelectedChefPackages: createSelector(
    'selectChefPackageData',
    teamData => teamData.filter(u => u.selected),
  ),

  selectActiveChefPackageId: createSelector(
    'selectRouteParams',
    routeParams => routeParams.chefPackageId,
  ),
  selectActiveChefPackage: createSelector(
    'selectChefPackageRaw',
    teamData => teamData.activeChefPackage,
  ),
  reactShouldFetchChefPackage: createSelector(
    'selectRouteApis',
    'selectChefPackageRaw',
    'selectCurrentUser',
    'selectActiveClient',
    (apis, teamData, currentUser, activeClient) => {
      const wantsChefPackages = apis.includes('chefPackages');

      if (
        !wantsChefPackages ||
        teamData.loading ||
        teamData.data ||
        !currentUser ||
        !activeClient
      ) {
        return false;
      }
      return { actionCreator: 'doFetchChefPackages' };
    },
  ),

  reactShouldFetchAllChefPackage: createSelector(
    'selectRouteApis',
    'selectChefPackageRaw',
    'selectCurrentUser',
    'selectActiveClient',
    (apis, teamData, currentUser, activeClient) => {
      const wantsChefPackages = apis.includes('allChefPackages');
      if (
        !wantsChefPackages ||
        teamData.loading ||
        teamData.data ||
        !currentUser ||
        !activeClient
      ) {
        return false;
      }
      return { actionCreator: 'doFetchAllChefPackages' };
    },
  ),
  reactShouldFetchActiveChefPackage: createSelector(
    'selectRouteParams',
    'selectPathname',
    'selectActiveChefPackage',
    'selectChefPackageRaw',
    (routeParams, pathname, activeChefPackage, teamData) => {
      if (
        !pathname.includes('/chef_packages') ||
        !routeParams.chefPackageId ||
        teamData.loadingActiveChefPackage
      ) {
        return null;
      }
      if (
        activeChefPackage &&
        activeChefPackage.id === routeParams.chefPackageId
      ) {
        return null;
      }

      return {
        actionCreator: 'doFetchActiveChefPackage',
        args: [routeParams.chefPackageId],
      };
    },
  ),

  reactShouldResetChefPackages: createSelector(
    'selectChefPackageRaw',
    'selectRouteApis',
    (chefData, apis) => {
      const all = apis.includes('allChefPackages');

      if (!chefData.data) {
        return false;
      }
      if (all !== chefData.all) {
        return { actionCreator: 'doResetChefPackages' };
      }
      return null;
    },
  ),

  reactShouldResetChefPackage: createSelector(
    'selectActiveChefPackageId',
    'selectActiveChefPackage',
    (activeChefPackageId, activeChefPackage, chefData, apis) => {
      if (!activeChefPackage) return null;
      if (activeChefPackage.id !== activeChefPackageId) {
        return { actionCreator: 'doResetChefPackage' };
      }

      return null;
    },
  ),
};
