import { createSelector } from 'redux-bundler';

import Directory from '../models/directory';

export default {
  name: 'directories',
  getReducer: () => {
    const initialData = {
      loading: false,
      directories: null,
    };
    // eslint-disable-next-line no-unused-vars
    return (state = initialData, { type, payload }) => {
      if (type === 'FETCH_DIRECTORIES') {
        return { ...state, loading: true };
      }
      if (type === 'FETCH_DIRECTORIES_SUCCESS') {
        return {
          ...state,
          loading: false,
          directories: payload.result,
        };
      }

      return state;
    };
  },

  doFetchDirectories: () => async ({ dispatch, getState, store }) => {
    const state = getState();
    const client = store.selectActiveClient(state).dup();
    dispatch({ type: 'FETCH_DIRECTORIES' });
    const scope = Directory.where({
      client_id: client.id,
    });

    const response = await scope.all();

    dispatch({
      type: 'FETCH_DIRECTORIES_SUCCESS',
      payload: { result: response.data },
    });
  },

  reactShouldFetchDirectories: createSelector(
    'selectRouteApis',
    'selectDirectoriesRaw',
    'selectCurrentUser',
    'selectActiveClient',
    (apis, directoriesRaw, currentUser, activeClient) => {
      const wantsDirectories = apis.includes('directories');
      if (
        !wantsDirectories ||
        directoriesRaw.loading ||
        directoriesRaw.directories ||
        !currentUser ||
        !activeClient
      ) {
        return false;
      }
      return { actionCreator: 'doFetchDirectories' };
    },
  ),

  selectDirectoriesRaw: state => state.directories,
  selectDirectories: state => state.directories.directories,
};
