import axios from 'axios';
import { ErrorHandler } from '../utilities/errorHandler';
import { OAuthConfiguration } from './oauthConfiguration';

/*
 * The entry point for making OAuth calls
 */
export class OAuthClient {
  configuration: OAuthConfiguration;
  antiForgeryToken: string | null;

  constructor(configuration: OAuthConfiguration) {
    this.configuration = configuration;
    this.antiForgeryToken = null;
    this.setupCallbacks();
  }

  /*
   * The anti forgery token is made available to the API client during API calls
   */
  getAntiForgeryToken() {
    return this.antiForgeryToken;
  }

  /*
   * On every page load the SPA asks the Token Handler API for login related state
   */
  async handlePageLoad(pageUrl) {
    const request = JSON.stringify({
      pageUrl,
    });

    const response = await this.fetch('POST', 'login/end', request);
    if (response && response.csrf) {
      this.antiForgeryToken = response.csrf;
    }

    console.log('handlePageLoad');
    console.log(response);
    return response;
  }

  /*
   * Invoked when the SPA wants to trigger a login redirect
   */
  async startLogin(state) {
    const data = await this.fetch(
      'POST',
      'login/start',
      JSON.stringify({ loginOptions: state }),
    );
    console.log(data.authorizationRequestUrl);
    return data.authorizationRequestUrl;
  }

  /*
   * Get user info from the API and return it to the UI for display
   */
  async getUserInfo() {
    return await this.fetch('GET', 'userInfo', null);
  }

  /*
   * Refresh the tokens stored in secure cookies when an API returns a 401 response
   */
  async refresh() {
    const data = await this.fetch('POST', 'refresh', null);
    return data.expiresAt;
  }

  /*
   * Perform logout actions
   */
  async logout() {
    const data = await this.fetch('POST', 'logout', null);
    this.antiForgeryToken = null;
    return data.url;
  }

  /*
   * Handle logout from another browser tab by clearing any secure values stored
   */
  async onLoggedOut() {
    this.antiForgeryToken = null;
  }

  /*
   * Call the Token Handler API in a parameterized manner
   */
  async fetch(method, path, body) {
    let url = `${this.configuration.tokenHandlerBaseUrl}/${path}`;
    const options = {
      url,
      method: method,
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
      },

      // Send the secure cookie to the API
      withCredentials: true,
    }; //as AxiosRequestConfig;

    if (body) {
      options.data = body;
    }

    // If we have an anti forgery token, add it to POST requests
    if (this.antiForgeryToken) {
      var headers = options.headers; //as AxiosRequestHeaders;
      headers['x-bff-csrf'] = this.antiForgeryToken;
    }

    try {
      // We use axios to call the Token Handler API, due to its support for reading error responses
      const response = await axios.request(options);
      if (response.data) {
        return response.data;
      }

      return null;
    } catch (e) {
      throw ErrorHandler.handleFetchError('Token Handler', e);
    }
  }

  /*
   * Set up methods invoked from DOM event handlers
   */
  setupCallbacks() {
    this.onLoggedOut = this.onLoggedOut.bind(this);
  }
}
