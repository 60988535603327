import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import ImagePipeline from './image_pipeline';
import User from './user';

@Model()
export default class ImageDefinition extends ApplicationRecord {
  static jsonapiType = 'image_definitions';
  @Attr() name: string;
  @Attr() runList: string;
  @Attr() version: number;
  @Attr() createdAt: string;
  @Attr() updatedAt: string;
  @Attr() createdByName: string;
  @Attr() usedSsmParameters: string[];
  @Attr({ persist: false }) selected: boolean;

  @BelongsTo() imagePipeline: ImagePipeline;
  @BelongsTo() createdBy: User;

  constructor(attrs?: Record<string, any>) {
    super(attrs);
    this.selected = false;
  }
}
