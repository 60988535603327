import { decode } from 'jsonwebtoken';
import {
  Mutex,
  MutexInterface,
  Semaphore,
  SemaphoreInterface,
  withTimeout,
} from 'async-mutex';
import SuperTokensLock from 'browser-tabs-lock';

export default class ManeAuth {
  static _currentUser: any;
  static _expiresAt: any;
  static _oauthClient: any;
  static _csrf: any;
  static mutex = new Mutex();

  static superTokensLock = new SuperTokensLock();

  static async getCurrentUser(): Promise<any> {
    // if (!this._currentUser) {
    //   const result = await fetch(
    //     `${process.env.REACT_APP_API_BASE_URL}/auth/authenticate`,
    //   );
    //   const user = await result.json();
    //   this._currentUser = user;
    // }
    return this._currentUser;
  }

  static setOauthClient(client: any) {
    this._oauthClient = client;
  }

  static setExpiresAt(expiresAt: any) {
    this._expiresAt = expiresAt;
  }

  static setCsrf(csrf: any) {
    this._csrf = csrf;
  }

  static getCsrf() {
    return this._oauthClient.getAntiForgeryToken();
  }

  static async logout(): Promise<any> {
    try {
      const url = await this._oauthClient.logout();

      window.location = url;
    } catch (err) {
      console.log('Error logging out', err);
      // window.location = '';
    }

    // const user = await this.getCurrentUser();
    // if (user) {
    //   const result = await fetch(
    //     `${process.env.REACT_APP_API_BASE_URL}/auth/logout`,
    //     {
    //       method: 'POST',
    //       credentials: 'include',
    //       headers: {
    //         Authorization: `Bearer ${user.access_token}`,
    //       },
    //     },
    //   );
    // }
    // this._currentUser = null;
  }

  static async refresh(): Promise<any> {
    const now = Date.now().valueOf() / 1000;

    return await this.mutex.runExclusive(async () => {
      if (this._expiresAt < now) {
        console.log(`Token has expired ${this._expiresAt}. Requesting refresh`);
        console.log('Waiting to acquire lock', Date.now().valueOf() / 1000);
        if (await this.superTokensLock.acquireLock('test', 5000)) {
          console.log('Lock acquired', Date.now().valueOf() / 1000);
          const expiresAt = await this._oauthClient.refresh();
          this._expiresAt = expiresAt;
          console.log(`New expiresAt: ${expiresAt}`);

          console.log('Lock released', Date.now().valueOf() / 1000);
          await this.superTokensLock.releaseLock('test');
        } else {
          console.log('Unable to retrieve lock', Date.now().valueOf() / 1000);
        }
      }
    });

    // console.log('Waiting to acquire lock', Date.now().valueOf() / 1000);
    // return await this.mutex.runExclusive(async () => {
    //   const now = Date.now().valueOf() / 1000;
    //   console.log('lock acquired', now);
    //   if (this._expiresAt < now) {
    //     console.log(`Token has expired ${this._expiresAt}. Requesting refresh`);
    //     const expiresAt = await this._oauthClient.refresh();
    //     this._expiresAt = expiresAt;
    //     console.log(`New expiresAt: ${expiresAt}`);
    //   }

    //   console.log('Lock released', Date.now().valueOf() / 1000);
    // });
  }

  static async getAuthenticationToken(): Promise<any> {
    // return localStorage.getItem
    // return await this.mutex.runExclusive(async () => {
    //   const user = await this.getCurrentUser();
    //   const parsedToken: any = decode(user.access_token);
    //   const now = Date.now().valueOf() / 1000;
    //   console.log(parsedToken);
    //   if (
    //     parsedToken &&
    //     typeof parsedToken.exp !== 'undefined' &&
    //     parsedToken.exp < now
    //   ) {
    //     console.log('Token has expired. Refreshing...');
    //     const result = await fetch(
    //       `${process.env.REACT_APP_API_BASE_URL}/auth/refresh`,
    //       {
    //         method: 'POST',
    //         credentials: 'include',
    //         headers: {
    //           Authorization: `Bearer ${user.access_token}`,
    //         },
    //       },
    //     );
    //     console.log('Refreshed');
    //     const nextUser = await result.json();
    //     this._currentUser = nextUser;
    //     return nextUser.access_token;
    //   }
    //   return user.access_token;
    // });
  }

  static setCurrentUser(user: any) {
    this._currentUser = user;
  }

  // static async getAccessToken(): any {
  //   if (!this._currentUser) {
  //     const result = await fetch(
  //       `${process.env.REACT_APP_API_BASE_URL}/auth/authenticate`,
  //       { credentials: 'include' },
  //     );
  //     const user = await result.json();
  //     console.log(user);
  //     this.currentUser = user;
  //     return user.access_token;
  //   }
  // }
}
