import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import Ansi from 'ansi-to-react';
import { connect } from 'redux-bundler-react';
import PropTypes from 'prop-types';
import React from 'react';

const LoggedOutDialog = ({ open }) => {
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm"
      fullWidth
      aria-labelledby="confirmation-dialog-title"
      open
    >
      <DialogTitle>Please sign in again</DialogTitle>
      <DialogContent>
        You have been signed out. Press reload to sign in again
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            window.location.reload();
          }}
          color="secondary"
        >
          Reload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

LoggedOutDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default connect(LoggedOutDialog);
