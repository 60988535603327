import { Model, Attr, HasMany, HasOne } from 'spraypaint';
import moment from 'moment';
import {
  sortBy,
  chain,
  flatten,
  forEachRight,
  uniqBy,
  mapValues,
} from 'lodash';

import { ApplicationRecord } from './application_record';
import Client from './client';
import ClientUserAssignment from './client_user_assignment';
import Permission from './permission';
import Role from './role';
import RoleUserAssignment from './role_user_assignment';
import Team from './team';
import Group from './group';
import TeamMembership from './team_membership';
import WorkstationLogin from './workstation_login';
import { faker } from '@faker-js/faker';

import Stat from './stat';

@Model()
export default class User extends ApplicationRecord {
  static jsonapiType = 'users';
  @Attr() firstName: string;
  @Attr() lastName: string;
  @Attr() name: string;
  @Attr() email: string;
  @Attr() username: string;
  @Attr() createdAt: string;
  @Attr() provider: string;
  @Attr() lastActivityAt: string;

  @Attr() masqueradeId: string;
  @Attr() avatarImageUrl: string;
  @Attr({ persist: false }) status: string;
  @Attr({ persist: false }) selected: boolean;
  @Attr() staff: boolean;

  @HasMany() roles: Role[];
  @HasMany() roleUserAssignments: RoleUserAssignment[];
  @HasMany() clientUserAssignments: ClientUserAssignment[];
  @HasMany() teamMemberships: TeamMembership[];
  @HasMany() teams: Team[];
  @HasMany() permissions: Permission[];
  @HasOne() lastWorkstationLogin: WorkstationLogin;

  @HasOne() userHoursStat: Stat;
  @HasOne() workstationHoursStat: Stat;

  constructor(attrs?: Record<string, any>) {
    super(attrs);
    this.selected = false;
  }

  availableTeams(allTeams: Team[]) {
    const teamIds = this.teams.map(team => team.id);
    return allTeams.filter(team => !teamIds.includes(team.id));
  }

  fullName(fake: Boolean): string {
    if (fake) {
      return `${this.fakeFirstName()} ${this.fakeLastName()}`;
    }
    return `${this.firstName} ${this.lastName}`;
  }

  emailAddress(fake: Boolean): string {
    if (fake) {
      return `${this.fakeFirstName().slice(
        0,
        1,
      )}${this.fakeLastName()}@company.com`.toLowerCase();
    }
    return this.email;
  }

  clientUserAssignmentForClient(activeClient: Client) {
    return this.clientUserAssignments.find(
      cua => cua.clientId === activeClient.id,
    );
  }

  totalHours(activeClient: Client) {
    const cua = this.clientUserAssignmentForClient(activeClient);
    return cua ? cua.totalHours : 0;
  }

  recentHours(activeClient: Client) {
    const cua = this.clientUserAssignmentForClient(activeClient);
    return cua ? cua.recentHours : 0;
  }

  mtdHours(activeClient: Client) {
    const cua = this.clientUserAssignmentForClient(activeClient);
    return cua ? cua.mtdHours : 0;
  }

  isAuthorized(permissionName: string) {
    return this.permissions.find(v => v.name === permissionName);
  }

  isAuthorizedAny(permissionNames: string[]) {
    return permissionNames.some(permissionName =>
      this.isAuthorized(permissionName),
    );
  }

  initials(fake: Boolean) {
    if (fake) {
      return [
        this.fakeFirstName().slice(0, 1),
        this.fakeLastName().slice(0, 1),
      ].join('');
    }
    return [this.firstName.slice(0, 1), this.lastName.slice(0, 1)].join('');
  }

  formattedCreatedAt() {
    return this.createdAt
      ? moment
          .utc(this.createdAt)
          .local()
          .format('MMM Do HH:mm')
      : '';
  }

  formattedLastActivityAt() {
    return this.lastActivityAt
      ? moment
          .utc(this.lastActivityAt)
          .local()
          .format('MMM Do HH:mm')
      : '';
  }

  fakeAvatar() {
    return User.fakeAvatar(this.fullName(false));
  }

  fakeFirstName() {
    return User.fakeFirstName(this.fullName(false));
  }

  fakeLastName() {
    return User.fakeLastName(this.fullName(false));
  }

  avatar(fake: Boolean) {
    if (fake) {
      return User.fakeAvatar(this.fullName(false));
    }
    return this.avatarImageUrl;
  }

  groups(): Group[] {
    return sortBy(
      uniqBy(flatten(this.teams.map(t => t.groups)), g => g.id),
      g => g.name,
    );
  }

  static seedFaker(seed: String) {
    const intSeed = seed
      .split('')
      .reverse()
      .map(c => c.charCodeAt(0))
      .reduce((a, b) => a + b, 0);

    faker.seed(intSeed);
  }

  static fakeAvatar(name: String) {
    this.seedFaker(name);
    return faker.internet.avatar();
  }

  static fakeFirstName(name: String) {
    this.seedFaker(name);
    return faker.name.firstName();
  }

  static fakeLastName(name: String) {
    this.seedFaker(name);
    return faker.name.lastName();
  }

  static fakeName(name: String) {
    return `${this.fakeFirstName(name)} ${this.fakeLastName(name)}`;
  }
}
