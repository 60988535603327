import { createSelector } from 'redux-bundler';

import SecurityGroup from '../models/security_group';

export default {
  name: 'securityGroups',
  getReducer: () => {
    const initialData = {
      loading: false,
      items: null,
    };
    // eslint-disable-next-line no-unused-vars
    return (state = initialData, { type, payload }) => {
      if (type === 'FETCH_SECURITY_GROUPS') {
        return { ...state, loading: true };
      }
      if (type === 'FETCH_SECURITY_GROUPS_SUCCESS') {
        return {
          ...state,
          loading: false,
          items: payload.result,
        };
      }

      return state;
    };
  },

  doFetchSecurityGroups: () => async ({ dispatch, getState, store }) => {
    const state = getState();
    const facility = store.selectActiveFacility(state).dup();
    dispatch({ type: 'FETCH_SECURITY_GROUPS' });
    const scope = SecurityGroup.where({
      facility_id: facility.id,
      kept: true,
    });

    const response = await scope.all();

    dispatch({
      type: 'FETCH_SECURITY_GROUPS_SUCCESS',
      payload: { result: response.data },
    });
  },

  reactShouldFetchSecurityGroups: createSelector(
    'selectRouteApis',
    'selectSecurityGroupsRaw',
    'selectCurrentUser',
    'selectActiveClient',
    (apis, securityGroupsRaw, currentUser, activeClient) => {
      const wantsSecurityGroups = apis.includes('securityGroups');
      if (
        !wantsSecurityGroups ||
        securityGroupsRaw.loading ||
        securityGroupsRaw.items ||
        !currentUser ||
        !activeClient
      ) {
        return false;
      }
      return { actionCreator: 'doFetchSecurityGroups' };
    },
  ),

  selectSecurityGroupsRaw: state => state.securityGroups,
  selectSecurityGroups: state => state.securityGroups.items,
};
