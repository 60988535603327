import React from 'react';

const with_reload = fn => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        console.log('Error', error);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
  });
};

export default fn => React.lazy(() => with_reload(fn));
