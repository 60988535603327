import LdapLocalAdminChange from '../models/ldap_local_admin_change';
import LdapNameChange from '../models/ldap_name_change';
import LdapUsernameChange from '../models/ldap_username_change';
import PasswordChange from '../models/password_change';

export default {
  name: 'password_changes',
  getReducer: () => {
    const initialData = {
      loading: false,
    };

    return (state = initialData, { type, payload }) => {
      return state;
    };
  },

  doChangePassword: password => async ({ dispatch, getState, store }) => {
    const state = getState();
    const client = store.selectActiveClient(state);
    const user = store.selectAuthenticatedUser(state);
    const cua = user.clientUserAssignments.find(
      cua => cua.clientId === client.id,
    );

    const pc = new PasswordChange({
      clientUserAssignment: cua,
      user,
      password: password,
    });
    await pc.save({ with: ['clientUserAssignment.id', 'user.id'] });
    return pc;
  },

  doResetPassword: (cua, user) => async ({ dispatch, getState, store }) => {
    const pc = new PasswordChange({
      clientUserAssignment: cua,
      user,
      resetPassword: true,
    });
    await pc.save({ with: ['clientUserAssignment.id', 'user.id'] });
    return pc;
  },

  // For setting password during user onboarding
  doSetPassword: password => async ({ dispatch, getState, store }) => {
    const state = getState();
    const client = store.selectActiveClient(state);
    const user = store.selectAuthenticatedUser(state);
    const invitation = store.selectActiveInvitation(state);
    const cua = user.clientUserAssignments.find(
      cua => cua.clientId === invitation.clientId,
    );

    const pc = new PasswordChange({
      clientUserAssignment: cua,
      user,
      password: password,
    });
    await pc.save({ with: ['clientUserAssignment.id', 'user.id'] });
    return pc;
  },

  doChangeUsername: (cua, user, username) => async ({
    dispatch,
    getState,
    store,
  }) => {
    const pc = new LdapUsernameChange({
      clientUserAssignment: cua,
      user,
      username: username,
    });
    await pc.save({ with: ['clientUserAssignment.id', 'user.id'] });
    if (pc.isPersisted) {
      store.doFetchActiveUser(user.id);
    }
    return pc;
  },

  doChangeName: (cua, user, name) => async ({ dispatch, getState, store }) => {
    const nc = new LdapNameChange({
      clientUserAssignment: cua,
      user,
      name: name,
    });
    await nc.save({ with: ['clientUserAssignment.id', 'user.id'] });
    if (nc.isPersisted) {
      store.doFetchActiveUser(user.id);
    }
    return nc;
  },

  doChangeLocalAdmin: (cua, user, localAdmin) => async ({
    dispatch,
    getState,
    store,
  }) => {
    const lac = new LdapLocalAdminChange({
      clientUserAssignment: cua,
      user,
      localAdmin: localAdmin,
    });
    await lac.save({ with: ['clientUserAssignment.id', 'user.id'] });
    if (lac.isPersisted) {
      store.doFetchActiveUser(user.id);
    }
    return lac;
  },
};
