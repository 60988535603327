import { Attr, BelongsTo, Model } from 'spraypaint';
import moment from 'moment';

import { ApplicationRecord } from './application_record';
import User from './user';
import Workstation from './workstation';

import { faker } from '@faker-js/faker';

@Model()
export default class RecentWorkstationLogin extends ApplicationRecord {
  static jsonapiType = 'recent_workstation_logins';
  @Attr() active: boolean;
  @Attr() name: string;
  @Attr() workstationName: string;
  @Attr() workstationId: string;
  @Attr() userId: string;
  @Attr() facilityId: string;
  @Attr() initials: string;
  @Attr() avatarImageUrl: string;
  @Attr() createdAt: string;
  @Attr() loggedOutAt: string;

  @BelongsTo() workstation: Workstation;
  @BelongsTo() user: User;

  formattedLoggedInAt() {
    return moment
      .utc(this.createdAt)
      .local()
      .format('MMM Do HH:mm');
  }

  formattedLoggedOutAt(prefix = '') {
    return this.loggedOutAt
      ? `${prefix}: ${moment
          .utc(this.loggedOutAt)
          .local()
          .calendar()}`
      : '';
  }

  fakeAvatar() {
    return User.fakeAvatar(this.name);
  }

  fakeFirstName() {
    return User.fakeFirstName(this.name);
  }

  fakeLastName() {
    return User.fakeLastName(this.name);
  }

  fakeName() {
    return `${this.fakeFirstName()} ${this.fakeLastName()}`;
  }
}
