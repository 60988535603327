import React from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Card,
  CardActionArea,
  Link,
  Typography,
  withStyles,
} from '@material-ui/core';

const styles = theme => ({
  cardActionArea: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
  },
  titleAndTextWrapper: {
    marginRight: theme.spacing(4),
  },
});

function DropdownItem({ button, classes, disabled, title, text, onClick, to }) {
  return (
    <>
      <Divider />
      <Link href={to} underline="none">
        <Card square elevation={0}>
          <CardActionArea
            disabled={disabled}
            onClick={onClick}
            className={classes.cardActionArea}
          >
            <div className={classes.titleAndTextWrapper}>
              <Typography
                variant="h6"
                color={disabled ? 'textSecondary' : 'textPrimary'}
              >
                {title}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {text}
              </Typography>
            </div>
            {button}
          </CardActionArea>
        </Card>
      </Link>
    </>
  );
}

DropdownItem.propTypes = {
  button: PropTypes.node,
  disabled: PropTypes.bool,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  to: PropTypes.string,
};

DropdownItem.defaultProps = {
  button: null,
  disabled: false,
  onClick: null,
  to: null,
};

export default withStyles(styles, { withTheme: true })(DropdownItem);
